import { Title, BackgroundImage, Box, SimpleGrid } from '@mantine/core';
import { Logo, SignInForm } from '@medplum/react';
// import { SignInForm } from '../components/auth/v3.0.3/SignInForm'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MEDPLUM_GOOGLE_CLIENT_ID,  isRegisterEnabled } from '../config';

export function SignInPage(): JSX.Element {
  // const profile = useMedplumProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let emailAuth = true;

  // Check for querystring param to enable email auth
  if (searchParams.get('emailauth') === 'true') {
    emailAuth = false;
  }

  return (
    <SimpleGrid cols={2}>
      <Box pt={100} pb={200}>
        <SignInForm
          projectId=''                                // Leave blank to force ProjectID from GoogleClientID
          googleClientId={MEDPLUM_GOOGLE_CLIENT_ID}   
          onSuccess={() => navigate('/')}
          onForgotPassword={() => navigate('/resetpassword')}
          onRegister={isRegisterEnabled() ? () => navigate('/register') : undefined}
          disableEmailAuth={emailAuth}
          disableGoogleAuth={false}
        >
          <Logo size={32} />
          <h2>Sign in to Gestalt PathCloud</h2>
          {searchParams.get('loginstatus') === 'first' && <div>Your new PathCloud container has been created.  Sign-in again to launch.</div>}
        </SignInForm>
      </Box>
      <BackgroundImage src="../../src/img/landingPage/laboratories+and+testing-1920w.jpg" />
    </SimpleGrid>
  );
}
