/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useMemo, useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css'; //if using mantine component features
import 'mantine-react-table/styles.css'; //make sure MRT styles were imported in your app root (once)
import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table';
import { Paper, Box, Drawer, Text, Title, Modal, Button, Textarea, TextInput, Group, Checkbox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCopy, IconSend2 } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { useMedplumPathcloudClient } from '../../hooks/useMedplumPathcloud';
import { ReportQuestionnaire } from '../../components/diagnosticreport/ReportQuestionnaire';
import { ImagingStudyList } from './types';
import { query_ImagingStudyList } from './queries';
import classes from './StudyWorklist.module.css';
import { PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET } from '../../config';
import { DICOMViewer, WorklistID, WorklistSettings } from './utils/configs';
import {
  getStudyUIDColumn,
  getPatientNameColumn,
  getModalityColumn,
  getStudyDescriptionColumn,
  getLocationColumn,
  getStudyStatusColumn,
  getDateColumn,
  getLaunchReportColumn,
  getLaunchActionsColumn,
  openViewer,
  renderViewerMenuItem,
} from './utils/columns';

function getQuery(config: WorklistSettings): string {
  const queryFilter = config.queryFilter || ''; // Set a default value if config.queryFilter is undefined
  return query_ImagingStudyList.replace('$queryFilter', queryFilter);
}

// Remove incomplete records in graphql resultset
function filterImagingStudyObj(jsonObj: { imagingStudy: null }[] | null) {
  // Check if the input is an object
  if (typeof jsonObj !== 'object' || jsonObj === null) {
    return jsonObj;
  }
  jsonObj.forEach((item: { imagingStudy: null }, i: string | number) => {
    if (!Array.isArray(item.imagingStudy) && item.imagingStudy === null) {
      // @ts-expect-error: Object is possibly 'null'
      delete jsonObj[i];
    }
  });
  jsonObj = jsonObj.filter((val: any) => val);

  // Check that the minum required fields are present
  jsonObj = jsonObj.filter((val: any) => {
    return val.identifier.length > 0 && val.series.length > 0;
  });

  return jsonObj;
}

export function StudyWorklist({ config }: { config: WorklistSettings }): JSX.Element {
  const medplum = config.id != WorklistID.PUBLIC_UNAUTHENTICATED ? useMedplum() : useMedplumPathcloudClient();

  const [selectedDiagnosticReport, setDiagnosticReportId] = useState<string>();
  const [selectedPatient, setPatientId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModalShare, handlerShare] = useDisclosure(false);
  const [opened, { open, close }] = useDisclosure(false, {
    onOpen: () => {
      console.log('Opened');
    },
    onClose: () => console.log('Closed'),
  });
  const [diagnosticreports, setDiagnosticReports] = useState<ImagingStudyList[]>([]);
  const [worklistSettings, setWorklistSettings] = useState<WorklistSettings>(config);
  const [user_hint, setUserHint] = useState<string>('');

  // Set the initial state based on the passed prop
  useEffect(() => {
    setWorklistSettings(config);
  }, [config]);

  useEffect(() => {
    const loadPractitionerDefaultOrg = async () => {
      const practitionerID = medplum.getProfile()?.id;
      try {
        const practitionerRoles = await medplum.searchResources('PractitionerRole', {
          practitioner: `Practitioner/${practitionerID}`,
          _filter: '(organization pr true)',
        });

        for (const record of practitionerRoles) {
          if (record.active === true && record.organization?.reference) {
            let orgid = record.organization?.reference.replace('Organization/', '');
            if (orgid !== worklistSettings.orgid) {
              setWorklistSettings((prevState) => ({
                ...prevState,
                orgid: orgid,
                queryFilter: `(
                  _count: 100
                  _compartment: "Organization/${orgid}" 
                )`,
              }));
            }
            break;
          } else {
            console.log('Private Worklist Error: No User Org Available');
          }
        }
      } catch (err) {
        console.error('Failed to load default org:', err);
      }
    };

    if (
      worklistSettings.id !== WorklistID.PUBLIC_UNAUTHENTICATED &&
      medplum?.sessionDetails?.membership?.user?.display
    ) {
      setUserHint(`user_hint=${medplum.sessionDetails.membership.user.display}`);
    } else {
      setUserHint('');
    }

    if (worklistSettings.id === WorklistID.PRIVATE_01) {
      loadPractitionerDefaultOrg();
    }
  }, [medplum, worklistSettings.id]); // Note the dependency on worklistSettings.orgid

  useEffect(() => {
    const loadGraphQL = async () => {
      try {
        setIsLoading(true);
        if (worklistSettings.id === WorklistID.PUBLIC_UNAUTHENTICATED && medplum.getAccessToken() === undefined) {
          medplum.setBasicAuth(PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET);
          await medplum.startClientLogin(PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET);
          const loginPathCloudProject = medplum.getActiveLogin();

          // @ts-expect-error:  - LoginState type not available
          medplum.setActiveLogin(loginPathCloudProject);
        }

        const query = getQuery(worklistSettings);
        var results = await medplum
          .graphql(query)
          .then((data) => {
            if (worklistSettings.id === WorklistID.PUBLIC_UNAUTHENTICATED) {
              medplum.clear();
            } else {
            }
            return data;
          })
          .catch((err) => {
            if (worklistSettings.id === WorklistID.PUBLIC_UNAUTHENTICATED) {
              medplum.clear();
              console.log('Query Terminated. Session Cleared - Msg:', err);
            } else {
              console.log('Query Terminated - Msg:', err);
            }
          })
          .finally(() => {
            if (worklistSettings.id === WorklistID.PUBLIC_UNAUTHENTICATED) {
              medplum.clear();
              console.log('Query Complete. Session Cleared');
            }
          });

        results = JSON.parse(JSON.stringify(results)).data.ImagingStudyList;
        const filteredResults = filterImagingStudyObj(results);
        setIsLoading(false);
        // @ts-expect-error: Object is possibly 'null'
        setDiagnosticReports(filteredResults);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    if (worklistSettings.orgid) {
      loadGraphQL(); // This will only run when orgid changes
    }
  }, [worklistSettings.orgid]); // Trigger loadGraphQL when orgid change

  const data: ImagingStudyList[] = diagnosticreports;
  const columns = useMemo<MRT_ColumnDef<ImagingStudyList>[]>(
    () => [
      ...(worklistSettings.showStudyUID ? [getStudyUIDColumn()] : []),
      ...(worklistSettings.showPatient ? [getPatientNameColumn()] : []),
      getModalityColumn(),
      getStudyDescriptionColumn(),
      getLocationColumn(),
      getStudyStatusColumn(),
      getDateColumn('studyDate', 'Study Date', (row) => {
        const sDay = new Date(row.started);
        sDay.setHours(0, 0, 0, 0);
        return sDay;
      }),
      getDateColumn('lastUpdated', 'Last Updated', (row) => new Date(row.meta.lastUpdated)),
      ...(worklistSettings.showLaunchReport ? [getLaunchReportColumn(open, setDiagnosticReportId, setPatientId)] : []),
      ...(worklistSettings.showLaunchActions ? [getLaunchActionsColumn(handlerShare)] : []),
    ],
    [worklistSettings, open, handlerShare]
  );

  const table = useMantineReactTable({
    columns: columns,
    data: data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      showLoadingOverlay: isLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableHiding: true,
    enablePinning: true,
    enableRowActions: true,
    enableRowSelection: false,
    enableFullScreenToggle: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    positionActionsColumn: 'first',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Launch', //change header text
        size: 80, //make actions column wider
      },
    },
    mantinePaginationProps: {
      radius: 'sm',
      size: 'sm',
    },
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      density: 'xs',
      pagination: { pageIndex: 0, pageSize: 15 },
      columnPinning: { left: ['mrt-row-actions'] },
      columnVisibility: {
        studyID: false,
        studyUID: false,
      },
      sorting: [{ id: 'lastUpdated', desc: true }],
    },
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
      onDoubleClick: () => {
        const studyInstanceUid = row?.original?.identifier[0]?.value.replace('urn:oid:', '');
        const series = row?.original?.series?.[0];
        const modalityCode = series?.modality?.code;
        const seriesUid = series?.uid;

        if (worklistSettings.showDICOMViewer.includes(DICOMViewer.CLINICAL) && modalityCode === 'SM') {
          openViewer(DICOMViewer.CLINICAL, modalityCode, seriesUid, studyInstanceUid, worklistSettings, user_hint);
        } else if (worklistSettings.showDICOMViewer.includes(DICOMViewer.PACS)) {
          openViewer(DICOMViewer.PACS, modalityCode, seriesUid, studyInstanceUid, worklistSettings, user_hint);
        }
      },
    }),
    renderRowActionMenuItems: ({ row }) => {
      const studyInstanceUid = row?.original?.identifier[0]?.value.replace('urn:oid:', '');
      const series = row?.original?.series?.[0];
      const modalityCode = series?.modality?.code;
      const seriesUid = series?.uid;
      const dcm_store_url = row?.original?.endpoint?.[0].Endpoint.address;

      return (
        <>
          {worklistSettings.showDICOMViewer.includes(DICOMViewer.AISTUDIO) &&
            modalityCode === 'SM' &&
            renderViewerMenuItem(
              DICOMViewer.AISTUDIO,
              'blue',
              'AI Viewer',
              modalityCode,
              seriesUid,
              studyInstanceUid,
              worklistSettings,
              user_hint
            )}

          {worklistSettings.showDICOMViewer.includes(DICOMViewer.PACS) &&
            renderViewerMenuItem(
              DICOMViewer.PACS,
              'purple',
              'PACS Viewer',
              modalityCode,
              seriesUid,
              studyInstanceUid,
              worklistSettings,
              user_hint
            )}

          {worklistSettings.showDICOMViewer.includes(DICOMViewer.CLINICAL) &&
            modalityCode === 'SM' &&
            renderViewerMenuItem(
              DICOMViewer.CLINICAL,
              'orange',
              'Clinical Viewer',
              modalityCode,
              seriesUid,
              studyInstanceUid,
              worklistSettings,
              user_hint
            )}

          {worklistSettings.showDICOMViewer.includes(DICOMViewer.GCP) &&
            modalityCode === 'SM' &&
            renderViewerMenuItem(
              DICOMViewer.GCP,
              'yellow',
              'GCP Viewer',
              modalityCode,
              seriesUid,
              studyInstanceUid,
              worklistSettings,
              user_hint
            )}
        </>
      );
    },
    renderDetailPanel: ({ row }) => (
      <Box
        style={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
        <Text>
          DiagnosticReportID:{' '}
          {// @ts-expect-error: TODO: Fix typing of resultset; add optional
          row?.original?.servicerequest[0]?.ServiceRequest?.diagnosticreport[0].id?.toString()}
        </Text>
        <Text>ImageStudyID: {row.original.id}</Text>
        <Text>
          StudyUID:{' '}
          {
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            row.original.identifier[0].value.replace('urn:oid:', '')
          }
        </Text>
      </Box>
    ),

    mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
    mantinePaperProps: { withBorder: false },
    mantineSearchTextInputProps: { placeholder: 'Search Images' },
  });

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <Title mb="lg">{worklistSettings.title}</Title>
      <Drawer position="right" opened={opened} onClose={close} title="Report Panel" size={'600px'}>
        <ReportQuestionnaire diagnosticreportId={selectedDiagnosticReport} patientId={selectedPatient} />
      </Drawer>
      <MantineReactTable table={table} />

      {/* Added Image Share Modal mockup to main page. TODO move to component */}
      <Modal opened={openModalShare} onClose={() => handlerShare.close()} title="Share Images">
        <TextInput label="Email" placeholder="Enter email address..." />
        <Textarea data-autofocus label="Message" placeholder="Add comments to recipient..." mt="md" />
        <Group justify="right">
          <Checkbox defaultChecked label="Anonymize?" variant="outline" />
          <Button leftSection={<IconCopy size={14} />} mt="md">
            Copy Link
          </Button>
          <Button leftSection={<IconSend2 size={14} />} mt="md">
            Send
          </Button>
        </Group>
      </Modal>
    </Paper>
  );
}
