/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-control-regex */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
import { Paper, Stepper, Fieldset, Tooltip, Button, Group, TextInput, FileInput, Title, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMedplum } from '@medplum/react';
import { DateInput, TimeInput } from '@mantine/dates';
import { IconUpload, IconClock, IconCalendar, IconRefresh } from '@tabler/icons-react';
import { WSIDropzone } from './WSIDropzone';
import { IMAGE_TRANSFORM_SVC_URL, IMAGE_TRANSFORM_SVC_METHOD, MEDPLUM_PROJECT_ID, PATHCLOUD_ORG_PUBLIC_01 } from '../../config';
import { WSIUploadList } from './WSIUploadList';
import classes from './FileUploadWizard.module.css';


type PathcloudIsPublicImageState = true | false;
type GestaltPrivateTags = 'Gestalt Private Tags';

interface DICOMMetaData {
  metadata?: [object];
  fileMetadata?: File;
  PatientBirthDate?: string;
  PatientID?: string;
  PatientName?: string;
  PatientSex?: string;
  AccessionNumber?: string;
  ReferringPhysicianName?: string;
  StudyDate?: string;
  StudyDescription?: string;
  StudyID?: string;
  StudyTime?: string;
  StudyInstanceUID?: string;
  Manufacturer?: string;
  GestaltPrivateTags: GestaltPrivateTags;
  PathcloudProjectID: string;
  PathcloudUserProjectID: string;
  PathcloudUserOrgID?: string;
  PathcloudPublicOrg01ID?: string;
  PathcloudPractitionerID?: string;
  PathcloudIsPublicImage: PathcloudIsPublicImageState;
}

function convertDICOMMetaDataToFile(dcmData: DICOMMetaData): string {
  const metadata = [];

  // Iterate over the dcmData fields and add them to the metadata
  for (let field of [
    'PatientBirthDate',
    'PatientID',
    'PatientName',
    'PatientSex',
    'AccessionNumber',
    'ReferringPhysicianName',
    'StudyDescription',
    'StudyID',
    'StudyDate',
    'StudyTime',
    'StudyInstanceUID',
    'Manufacturer',

    'GestaltPrivateTags',
    'PathcloudProjectID',
    'PathcloudUserProjectID',
    'PathcloudUserOrgID',
    'PathcloudPublicOrg01ID',
    'PathcloudPractitionerID',
    'PathcloudIsPublicImage',
  ]) {
    // @ts-expect-error: TODO: fix the type interface
    if (dcmData[field] || field === 'PathcloudIsPublicImage') { 
      let vrValue = 'LO';
      // @ts-expect-error: TODO: fix the type interface
      let value = dcmData[field];
      if (field === 'PatientBirthDate' || field === 'StudyDate') {
        vrValue = 'DA';
        value = new Date(value);
        value = `${value.getUTCFullYear()}${(value.getUTCMonth() + 1).toString().padStart(2, '0')}${value
          .getUTCDate()
          .toString()
          .padStart(2, '0')}`;
      } else if (field === 'StudyTime') {
        vrValue = 'TM';
        value = value.replace(/:/g, '') + '000';
      } else if (field === 'PatientSex') {
        vrValue = 'CS';
      } else if (field === 'AccessionNumber' || field === 'StudyID') {
        vrValue = 'SH';
      } else if (field === 'ReferringPhysicianName' || field === 'PatientName') {
        vrValue = 'PN';
        value = { Alphabetic: value };
      } else if (field === 'StudyInstanceUID') {
        vrValue = 'UI';
      } else if (field === 'Manufacturer') {
        vrValue = 'LO';
      } else if (field === 'GestaltPrivateTags') {
        field = '00650010';
      } else if (field === 'PathcloudProjectID') {
        field = '00651000';
      } else if (field === 'PathcloudUserProjectID') {
        field = '00651001';
      } else if (field === 'PathcloudUserOrgID') {
        field = '00651002';
      } else if (field === 'PathcloudPublicOrg01ID') {
        field = '00651003';
      } else if (field === 'PathcloudPractitionerID') {
        field = '00651004';
      } else if (field === 'PathcloudIsPublicImage') {
        field = '00651012';
        value = value.toString();
        vrValue = 'SH';
      }

      metadata.push({ [field]: { Value: [value], vr: vrValue } });
    }
  }
  // console.log('DCM_metadata=',JSON.stringify(metadata))
  return JSON.stringify(metadata);
}

export function FileUploadWizard() {
  const medplum = useMedplum();
  // const [practitionerOrgs, setPractitionerOrgs] = useState<PractitionerRole>();
  const [practitionerDefaultOrg, setPractitionerDefaultOrg] = useState();
  const [active, setActive] = useState(0);
  
  useEffect(() => {
    const loadPractitionerDefaultOrg = async (): Promise<void> => {
      const practitionerID = medplum.getProfile()?.id;
      try {
        const practitionerRoles = await medplum.searchResources('PractitionerRole', {
          practitioner: `Practitioner/${practitionerID}`,
          _filter: '(organization pr true)'
        });

        // setPractitionerOrgs(practitionerRoles);
        for (const record of practitionerRoles ) {
          if (record.active === true) {
            // @ts-expect-error: ignore possible null reader
            setPractitionerDefaultOrg(record.organization?.reference);
            break;
          } else {
            // @ts-expect-error: ignore possible null reader
            setPractitionerDefaultOrg('Unknown')
          }
        }   
      } catch(err) {
        console.log(err);
      }
    };
    loadPractitionerDefaultOrg();
    
  },[medplum])
  
  const form = useForm<DICOMMetaData>({
    initialValues: {
      PatientBirthDate: '',
      PatientID: '',
      PatientName: '',
      PatientSex: '',
      AccessionNumber: '',
      ReferringPhysicianName: '',
      // StudyDate: new Date().toLocaleDateString("en-US"),
      StudyDate: '',
      StudyDescription: '',
      StudyID: '',
      // StudyTime: new Date().toLocaleTimeString("en-US"),
      StudyTime: '',
      StudyInstanceUID: '',
      Manufacturer: 'Gestalt Diagnostics',

      GestaltPrivateTags: 'Gestalt Private Tags',
      PathcloudProjectID: MEDPLUM_PROJECT_ID || 'Unknown',
      PathcloudUserProjectID: medplum.getProject()?.id || 'Unknown',
      PathcloudUserOrgID: practitionerDefaultOrg || 'Unknown',
      PathcloudPublicOrg01ID: PATHCLOUD_ORG_PUBLIC_01 || 'Unknown',
      PathcloudPractitionerID: medplum.getProfile()?.id || 'Unknown',
      PathcloudIsPublicImage: false,
    },

    validate: (values) => {
      // Validate only active steps
      // Validate the uploaded file
      if (active === 0) {
        const reader = new FileReader();
        reader.onload = () => {
          // @ts-expect-error: ignore possible null reader
          form.values.metadata = JSON.parse(reader.result.toString());
          // Check if the metadata contains the supported fields
          for (const field of [
            'PatientID',
            'PatientName',
            'PatientSex',
            'AccessionNumber',
            'ReferringPhysicianName',
            'StudyDescription',
            'StudyID',
            'StudyInstanceUID',
            'PathcloudPublicOrg01ID',
            'PathcloudUserOrgID',
            'PathcloudProjectID',
            'PathcloudUserProjectID',
            'PathcloudIsPublicImage',
          ]) {
            if (form.values.metadata?.find((obj) => Object.hasOwn(obj, field))) {
              // @ts-expect-error: obj parameter fix
              form.setFieldValue(field, form.values.metadata.find((obj) => obj.hasOwnProperty(field))[field].Value[0]);
            }
          }

          for (const field of ['PatientBirthDate']) {
            if (form.values.metadata?.find((obj) => obj.hasOwnProperty(field))) {
              // @ts-expect-error: obj parameter fix
              const date = form.values.metadata.find((obj) => obj.hasOwnProperty(field))[field].Value[0];
              form.setFieldValue(field, new Date(date.substr(0, 4), date.substr(4, 2), date.substr(6, 2)));
            }
          }

          for (const field of ['StudyDate']) {
            if (form.values.metadata?.find((obj) => obj.hasOwnProperty(field))) {
              form.setFieldValue(field, new Date());
            }
          }

          for (const field of ['StudyTime']) {
            if (form.values.metadata?.find((obj) => obj.hasOwnProperty(field))) {
              // @ts-expect-error: obj parameter fix
              const date = form.values.metadata?.find((obj) => obj.hasOwnProperty(field))[field].Value[0];
              // HHMMSS.FFFFFF
              form.setFieldValue(
                field,
                `${date.substr(0, 2)}:${date.substr(2, 2)}:${date.substr(4, 2)}.${date.substr(7, 3)}`
              );
            }
          }
        };

        if (values.fileMetadata !== undefined && values.fileMetadata.type === 'application/json') {
          reader.readAsText(values.fileMetadata);
        }

        return {
          fileMetadata: null,
        };
      }

      if (active === 1) {
        return {
          PatientBirthDate: null,
          // https://dicom.innolitics.com/ciods/rt-plan/patient/00101002/00100020
          // @ts-expect-error: values obj fix
          PatientID: /^[\x20-\x7E\x1B]{0,64}$/.test(values.PatientID) ? null : 'Max 64 characters',
          // https://dicom.innolitics.com/ciods/rt-plan/patient/00100010
          PatientName:
            // @ts-expect-error: values obj fix
            values.PatientName === '' || values.PatientName.split(/\^/).length <= 5
              ? null
              : 'family name complex^given name complex^middle name^name prefix^name suffix. Example: Doe^John^Middle^Mr.^Jr. or empty',
          // https://dicom.innolitics.com/ciods/rt-plan/patient/00100040
          // @ts-expect-error: values obj fix
          PatientSex: /^(male|female|other)?$/.test(values.PatientSex) ? null : 'male, female, other or empty',
          // https://dicom.innolitics.com/ciods/enhanced-sr/general-study/00080050
          // @ts-expect-error: values obj fix
          AccessionNumber: /^[\x20-\x7E\x1B]{0,16}$/.test(values.AccessionNumber) ? null : 'Max 16 characters',
          // https://dicom.innolitics.com/ciods/lensometry-measurements/general-study/00080090
          ReferringPhysicianName:
            // @ts-expect-error: values obj fix
            values.ReferringPhysicianName === '' || values.ReferringPhysicianName.split(/\^/).length <= 5
              ? null
              : 'family name complex^given name complex^middle name^name prefix^name suffix. Example: Doe^John^Middle^Mr.^Jr. or empty',
          // https://dicom.innolitics.com/ciods/cr-image/general-study/00080020
          StudyDate: null,
          // https://dicom.innolitics.com/ciods/cr-image/general-study/00081030
          // @ts-expect-error: values obj fix
          StudyDescription: /^[\x20-\x7E\x1B]{0,64}$/.test(values.StudyDescription) ? null : 'Max 64 characters',
          // https://dicom.innolitics.com/ciods/12-lead-ecg/general-study/00200010
          // @ts-expect-error: values obj fix
          StudyID: /^[\x20-\x7E\x1B]{0,16}$/.test(values.StudyID) ? null : 'Max 16 characters',
          // https://dicom.innolitics.com/ciods/cr-image/general-study/00080030
          // HHMMSS.FFFFFF
          StudyTime: null,
          StudyInstanceUID:
            // @ts-expect-error: values obj fix
            values.StudyInstanceUID === '' || /^(?!^\.)[0-9]+(\.[0-9]+)*$/.test(values.StudyInstanceUID)
              ? null
              : 'Max 64 characters, dots separated by numbers',
        };
      }

      return {};
    },
  });

  function fetchDICOMUid(url: string) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => form.setFieldValue('StudyInstanceUID', data.uid));
  }

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      // Re-initialize form with user values
      form.initialize({
        GestaltPrivateTags: 'Gestalt Private Tags',
        PathcloudProjectID: MEDPLUM_PROJECT_ID || 'Unknown',
        PathcloudUserProjectID: medplum.getProject()?.id || 'Unknown',
        PathcloudUserOrgID: practitionerDefaultOrg || 'Unknown',
        PathcloudPublicOrg01ID: PATHCLOUD_ORG_PUBLIC_01 || 'Unknown',
        PathcloudPractitionerID: medplum.getProfile()?.id || 'Unknown',
        PathcloudIsPublicImage: false,
      })
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <Title order={2} mb="md">
        WSI Upload and DICOM conversion
      </Title>
      <Stepper active={active}>
        <Stepper.Step label="(Optional) Upload metadata" description="Upload DICOM metadata as a json file">
          <FileInput
            label="Upload metadata"
            placeholder="Upload metadata"
            leftSection={<IconUpload size={14} />}
            accept="application/json"
            {...form.getInputProps('fileMetadata')}
          />
        </Stepper.Step>

        <Stepper.Step label="Metadata" description="Optional metadata">
          <Fieldset legend="Patient Information">
            <DateInput
              label="Patient Birth Date"
              leftSection={<IconCalendar size={14} />}
              {...form.getInputProps('PatientBirthDate')}
            />
            <TextInput label="Patient ID" {...form.getInputProps('PatientID')} />
            <TextInput label="Patient Name" {...form.getInputProps('PatientName')} />
            <TextInput label="Patient Sex" placeholder="M|F|O" {...form.getInputProps('PatientSex')} />
            <TextInput label="Referring Physician Name" {...form.getInputProps('ReferringPhysicianName')} />
          </Fieldset>

          <Fieldset legend="Study Information">
            
            <Group grow>
              
              <TextInput label="User Org ID" {...form.getInputProps('PathcloudUserOrgID')} readOnly={true} />
              <Tooltip label="Click Checkbox to Make Image Public" refProp="rootRef">
                <Checkbox label="Make Image Public?" color="orange" {...form.getInputProps('PathcloudIsPublicImage')}/>
              </Tooltip>
            </Group>
            
            <Tooltip label="If not empty, the same StudyInstanceUID will be used for all WSI files. Click refresh to create a vaild UID">
              <TextInput
                label="StudyInstanceUID"
                placeholder="If empty, a new UID will be generated for each WSI file."
                leftSection={
                  <IconRefresh size={14} onClick={() => fetchDICOMUid(`${IMAGE_TRANSFORM_SVC_URL}/generate_uid`)} />
                }
                {...form.getInputProps('StudyInstanceUID')}
              />
            </Tooltip>

            <TextInput label="Accession Number" {...form.getInputProps('AccessionNumber')} />
            <TextInput label="Study Description" {...form.getInputProps('StudyDescription')} />
            <TextInput label="Study ID" {...form.getInputProps('StudyID')} />
            <DateInput
              label="Study Date"
              leftSection={<IconCalendar size={14} />}
              {...form.getInputProps('StudyDate')}
            />
            <TimeInput label="Study Time" leftSection={<IconClock size={14} />} {...form.getInputProps('StudyTime')} />
          </Fieldset>
        </Stepper.Step>

        <Stepper.Step label="WSI" description="Upload WSIs">
          <WSIDropzone
            metadata={convertDICOMMetaDataToFile(form.values)}
            serverUrl={`${IMAGE_TRANSFORM_SVC_URL}/${IMAGE_TRANSFORM_SVC_METHOD}`}
          />
        </Stepper.Step>

        <Stepper.Completed>Completed! Merged DICOM values</Stepper.Completed>
      </Stepper>

      <br />
      <Group justify="flex-end">
        {active > 0 && (
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
        )}
        {active < 2 && <Button onClick={nextStep}>Next step</Button>}
      </Group>
      <WSIUploadList />
    </Paper>
  );
}
