/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Flex, Text } from '@mantine/core';
import { Questionnaire, QuestionnaireResponse, Task, Patient, DiagnosticReport } from '@medplum/fhirtypes';
import { Document, QuestionnaireForm, useMedplum } from '@medplum/react';
import { IconCircleCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { PatientHeader } from '../../components/admin/PatientHeader';
import { defaultDiagnosticReportQuestionnaire } from './DiagnosticReport.questionnaire';

// @ts-expect-error: TODO: 
export function ReportQuestionnaire({diagnosticreportId, patientId}): JSX.Element {
  // const { id } = useParams();
  const medplum = useMedplum();
  const [submitted, setSubmitted] = useState(false);
  // const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [patient, setPatient] = useState<Patient>();
  const [diagnosticreport, setDiagnosticReport] = useState<DiagnosticReport>();

  useEffect(() => {
    // medplum.searchOne('Questionnaire', 'name:contains=PathologyReportTemplate01').then(setQuestionnaire).catch(console.log);
    medplum.readResource('DiagnosticReport', diagnosticreportId).then(setDiagnosticReport).catch(console.log);
    medplum.readResource('Patient', patientId).then(setPatient).catch(console.log);
  }, [medplum]);

  
  // if (!questionnaire) {
  //   return <div>Loading...</div>;
  // }

  async function handleSubmit(questionnaireResponse: QuestionnaireResponse): Promise<void> {
    const response = await medplum.createResource(questionnaireResponse);
    const newTask: Task = {
      resourceType: 'Task',
      status: 'ready',
      intent: 'order',
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '60568-3',
            display: 'Pathology Report',
          },
        ],
      },
      focus: {
        reference: `QuestionnaireResponse/${response.id}`,
      },
      for: {
        reference: `DiagnosticReport/${diagnosticreportId}`,
      },
    };
    await medplum.createResource(newTask);
    setSubmitted(true);
  }

  return (
    <Document>
      {patient && <PatientHeader patient={patient} />}
      {/* <div style={{display: 'flex'}}>
        <IconReportMedical size={24} style={{ marginRight: 4, right: 400 }} />
        <Text fz="xl" fw={700} tt="capitalize">Report: {diagnosticreport?.code.coding?}</Text>
      </div>
      <div style={{display: 'flex', paddingBottom: 25}}>
        <Text fz="md" fw={500} c="dimmed">DiagnosticReportID: </Text>
        <Text fz="md" c="dimmed">{diagnosticreport?.id}</Text>
      </div> */}
      
      <QuestionnaireForm 
        submitButtonText={'Save'} 
        questionnaire={defaultDiagnosticReportQuestionnaire} 
        onSubmit={!submitted ? handleSubmit : console.log} 
      />

      {submitted ? (
        <Flex justify={'flex-end'} mt={8}>
          <Text mr={8}>Submitted</Text>
          <IconCircleCheck color="#79d290" size={24} />
        </Flex>
      ) : null}
    </Document>
  );
}
