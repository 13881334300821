import { Box, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { formatDate, getReferenceString } from '@medplum/core';
import { Patient } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { InfoButton } from '../../components/InfoButton';
import { InfoSection } from '../../components/InfoSection';
import { query_ImagingStudyList } from './queries'

export function LandingWorklist(): JSX.Element {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const medplum = useMedplum();
  // const patient = medplum.getProfile() as Patient;
  const reports = medplum.searchResources('Patient').read();
  // const practitionerRoles = medplum.searchResources('PractitionerRole', {
  //   practitioner: `Practitioner/${medplum.getProfile()?.id}`,
  //   _filter: '(organization pr true)'
  // }).read();
  // // const DiagnosticReports = medplum.searchResources('Patient', {
  // //   _has: 'DiagnosticReport:imagingStudy:started=2024-7-16',
  // // }).read();

  // const Test = medplum.searchResources('ImagingStudy', {
  //     endpoint : 'Endpoint/fb2a4ee6-c967-4578-b301-3ddac3599947',
  //     // started : 'gt2024-03-18',
  //     _lastUpdated: 'gt2024-03-18',
  //     _tag: 'imagingstudy-tags|TestValue',
  //     _count: 100,
  //   _include: 'ImagingStudy:basedon',
  //   '_include:iterate': 'ImagingStudy:patient',
  //   '_revinclude:iterate': 'DiagnosticReport:based-on',}).read();
  // const endpoint = medplum.searchResources('Endpoint', {
  //   _id : 'Endpoint/fb2a4ee6-c967-4578-b301-3ddac3599947',
  // }).read();

  const queryResult = medplum.graphql(query_ImagingStudyList)
    .then(rs => {console.log(rs);})
    .then (rs => {return rs})
    .catch(err => {console.log(err)})
  
  // console.log('practitionerRoles=', practitionerRoles)
  // console.log('ResourceArray=', Test)
  // console.log('endpoint=', endpoint)
  console.log('queryResult=', queryResult)
  return (
    <Box p="xl">
      <Title mb="lg">Landing</Title>
      <InfoSection title="Public">
        <Stack gap={0}>
          {reports.map((report) => (
            <InfoButton key={report.id} onClick={() => navigate(`./${report.id}`)}>
              <div>
                <Text fw={500} mb={4}>
                  {formatDate(report.meta?.lastUpdated as string)}
                </Text>
                <Text>{}</Text>
              </div>
              <IconChevronRight color={theme.colors.gray[5]} />
            </InfoButton>
          ))}
        </Stack>
      </InfoSection>
      <InfoSection title="My Archive">
        <Stack gap={0}>
        </Stack>
      </InfoSection>
    </Box>
  );
}
