/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Paper, Title } from '@mantine/core';
import {
  DEFAULT_SEARCH_COUNT,
  Filter,
  formatSearchQuery,
  parseSearchDefinition,
  SearchRequest,
  SortRule,
} from '@medplum/core';
import { UserConfiguration } from '@medplum/fhirtypes';
import { Loading, MemoizedSearchControl, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './SearchImagingStudyPage.module.css';

export function SearchImagingStudyPage(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<SearchRequest>();

  useEffect(() => {
    const parsedSearch = parseSearchDefinition(location.pathname + location.search);

    const populatedSearch = addSearchValues(parsedSearch, medplum.getUserConfiguration());

    if (
      location.pathname === `/${populatedSearch.resourceType}` &&
      location.search === formatSearchQuery(populatedSearch)
    ) {
      saveLastSearch(populatedSearch);
      setSearch(populatedSearch);
    } else {
      navigate(`/${populatedSearch.resourceType}${formatSearchQuery(populatedSearch)}`);
    }
  }, [medplum, navigate, location]);

  if (!search?.resourceType || !search.fields || search.fields.length === 0) {
    return <Loading />;
  }

  // @ts-expect-error: ignore any type error
  function onClickImagingStudy(e) {
    const url = `https://azvm-mlops-b8.westus2.cloudapp.azure.com/images/dcmweb/${e.resource.identifier[0].value.replace(
      'urn:oid:',
      ''
    )}/${
      e.resource.series[0].uid
    }/?server=https://healthcare.googleapis.com/v1/projects/gcp-pathology-poc1/locations/us-west2/datasets/dicom-pathology/dicomStores/slide-dicom-store/dicomWeb`;
    const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
      window.innerHeight * 0.75
    )}`;
    window.open(url, 'AI-Studio-Path-Cloud', windowFeatures);
    console.log('url=', url)
  }

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <Title>
        ImagingStudy List
      </Title>
      <MemoizedSearchControl
        checkboxesEnabled={true}
        search={search}

        onClick={(e) => onClickImagingStudy(e)}
        onAuxClick={(e) => console.log('auxClick', e)}
        onChange={(e) => {
          navigate(`/${search.resourceType}${formatSearchQuery(e.definition)}`);
        }}
      />
    </Paper>
  );
}

function addSearchValues(search: SearchRequest, config: UserConfiguration | undefined): SearchRequest {
  const resourceType = search.resourceType || getDefaultResourceType(config);
  const fields = search.fields ?? ['_id', '_lastUpdated'];
  const filters = search.filters ?? (!search.resourceType ? getDefaultFilters(resourceType) : undefined);
  const sortRules = search.sortRules ?? getDefaultSortRules(resourceType);
  const offset = search.offset ?? 0;
  const count = search.count ?? DEFAULT_SEARCH_COUNT;

  return {
    ...search,
    resourceType,
    fields,
    filters,
    sortRules,
    offset,
    count,
  };
}

function getDefaultResourceType(config: UserConfiguration | undefined): string {
  return (
    localStorage.getItem('defaultResourceType') ??
    config?.option?.find((o) => o.id === 'defaultResourceType')?.valueString ??
    'Task'
  );
}

function getDefaultFilters(resourceType: string): Filter[] | undefined {
  return getLastSearch(resourceType)?.filters;
}

function getDefaultSortRules(resourceType: string): SortRule[] {
  const lastSearch = getLastSearch(resourceType);
  if (lastSearch?.sortRules) {
    return lastSearch.sortRules;
  }
  return [{ code: '_lastUpdated', descending: true }];
}

function getLastSearch(resourceType: string): SearchRequest | undefined {
  const value = localStorage.getItem(resourceType + '-defaultSearch');
  return value ? (JSON.parse(value) as SearchRequest) : undefined;
}

function saveLastSearch(search: SearchRequest): void {
  localStorage.setItem('defaultResourceType', search.resourceType);
  localStorage.setItem(search.resourceType + '-defaultSearch', JSON.stringify(search));
}
