import { Box, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { formatDate, getReferenceString } from '@medplum/core';
import { Patient } from '@medplum/fhirtypes';
import { StatusBadge, useMedplum } from '@medplum/react';
import { usePatient } from '../../hooks/usePatient';
import { IconChevronRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { InfoButton } from '../../components/InfoButton';
import { InfoSection } from '../../components/InfoSection';
import { PATHCLOUD_VIEWER_ROOT_URL_CLINICAL, PATHCLOUD_VIEWER_ROOT_URL_PACS } from '../../config';

export function ImagesTab(): JSX.Element {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const medplum = useMedplum();
  const patient = usePatient() as Patient;

  const imagingstudys = medplum.searchResources('ImagingStudy', 'patient=' + getReferenceString(patient)).read();

  // @ts-expect-error: ignore any type error
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function onClickImagingStudy(image) {
    const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
      window.innerHeight * 0.75
    )}`;
    const url_hint =
      typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
        ? `user_hint=${medplum.sessionDetails.membership.user.display}`
        : '';
    const study_intance_uid = image.identifier[0].value.replace('urn:oid:', '');

    if (image.series[0].modality?.code === 'SM') {
      const url = `${PATHCLOUD_VIEWER_ROOT_URL_CLINICAL}/studies/${study_intance_uid}/series/${image.series[0].uid}?${url_hint}`;
      window.open(url, 'PathCloud-Clinical', windowFeatures);
    } else {
      const url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/viewer?StudyInstanceUIDs=${study_intance_uid}&${url_hint}`;
      window.open(url, 'OHIF-PathCloud', windowFeatures);
    }
  }

  return (
    <Box p="xl">
      <InfoSection title="Diagnostic Images">
        <Stack gap={0}>
          {imagingstudys.map((image) => (
            <InfoButton key={image.id} onClick={() => onClickImagingStudy(image)}>
              <div>
                <Text c={theme.primaryColor} fw={500} mb={4}>
                  {image.location?.display}
                </Text>
                <Text c="gray.6">{Array.isArray(image.modality) && image.modality[0].code}</Text>
              </div>
              Acquisition Date:<time> {formatDate(image.meta?.lastUpdated)} </time>
              <StatusBadge status={image.status as string} />
            </InfoButton>
          ))}
        </Stack>
      </InfoSection>
    </Box>
  );
}
