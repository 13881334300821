import { Paper, Tabs, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Communication, Resource, OperationOutcome } from '@medplum/fhirtypes';
import { normalizeErrorString, normalizeOperationOutcome } from '@medplum/core';
import { CodeableConceptDisplay, ResourceHistoryTable, ResourceTable, useMedplum } from '@medplum/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResourceElementKeys, ResourcePartialForm } from '../ResourcePartialForm'

interface CommunicationDetailsProps {
  readonly communication: Communication;
}

export function CommunicationDetails({ communication }: CommunicationDetailsProps): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const id = communication.id as string;
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const tabs = ['Details', 'History', 'Edit'];
  const communicationElementKeys: ResourceElementKeys[] = [
    {key: 'topic', title: '',description: ''},
    {key: 'status', title: '',description: ''},
    {key: 'subject', title: 'Subject',description: ''},
    {key: 'about', title: 'About', description: ''},
    {key: 'sender', title: 'Sender', description: ''},
    {key: 'recipient', title: 'Recipient', description: ''},
    {key: 'partOf', title: 'Part of', description: ''},
    {key: 'payload', title: '',description: ''}
  ] 

  // Get the current tab
  const tab = window.location.pathname.split('/').pop();
  const currentTab = tab && tabs.map((t) => t.toLowerCase()).includes(tab) ? tab : tabs[0].toLowerCase();

  function handleTabChange(newTab: string | null): void {
    navigate(`/Communication/${id}/${newTab ?? ''}`);
  }

  const handleSubmit = useCallback(
    (newResource: Resource): void => {
      setOutcome(undefined);
      medplum
        .updateResource(newResource)
        .then(() => {
          navigate(`/Communication/${id}/detail`);
          showNotification({ color: 'green', message: 'Success' });
        })
        .catch((err) => {
          setOutcome(normalizeOperationOutcome(err));
          showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false });
        });
    },
    [medplum, id, navigate]
  );
  
  
  const handleDelete = useCallback(
    () => {
      setOutcome(undefined);
      medplum
        .deleteResource(communication.resourceType, communication.id as string)
        .then(() => {
          navigate(`/Communication?_fields=sender,recipient,topic,subject,status,_lastUpdated&_sort=-_lastUpdated&part-of:missing=false&recipient=Practitioner%2F${medplum.getProfile()?.id}&status=completed`);
          showNotification({ color: 'green', message: 'Deleted Record' });
        })
        .catch((err) => {
          setOutcome(normalizeOperationOutcome(err));
          showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false });
        });
    },
    [medplum, id, navigate]
  );

  // const handleDelete = useCallback(() => navigate('..'), [navigate]);
  // const handleDelete = useCallback(() => navigate(`/${communication.resourceType}/${id}/delete`), [navigate, communication.resourceType, id]);

  return (
    <Paper m="md" p="md">
      <Title>
        <CodeableConceptDisplay value={communication.topic} />
      </Title>
      <Tabs defaultValue="details" value={currentTab.toLowerCase()} onChange={handleTabChange}>
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Tab key={tab} value={tab.toLowerCase()}>
              {tab}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="details">
          <ResourceTable value={communication} ignoreMissingValues={true} />
        </Tabs.Panel>
        <Tabs.Panel value="history">
          <ResourceHistoryTable resourceType="Communication" id={communication.id} />
        </Tabs.Panel>
        <Tabs.Panel value="edit">
          <ResourcePartialForm 
            defaultValue={communication} 
            onSubmit={handleSubmit} 
            onDelete={handleDelete}
            outcome={outcome} 
            resourceElementKeys={communicationElementKeys}
          />
        </Tabs.Panel>
      </Tabs>
    </Paper>
  );
}
