import { Box, Title } from '@mantine/core';
import { Task } from '@medplum/fhirtypes';
import { ResourceTable, useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';
import { InfoSection } from '../../components/InfoSection';

export function ActionItem(): JSX.Element {
  const medplum = useMedplum();
  const { itemId } = useParams();
  const resource: Task = medplum.readResource('Task', itemId as string).read();

  return (
    <Box p="xl">
      <Title order={2} mb="md">
        {Array.isArray(resource.code?.coding) && resource.code.coding[0].display}
      </Title>
      <InfoSection title="Task">
        <ResourceTable value={resource} ignoreMissingValues />
      </InfoSection>
    </Box>
  );
}
