import {
  Anchor,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Image,
  Overlay,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { formatHumanName } from '@medplum/core';
import { Patient, Practitioner } from '@medplum/fhirtypes';
import { useMedplumProfile } from '@medplum/react';
import { IconChecklist, IconAlertHexagon, IconSquareCheck } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import DoctorImage from '../img/homePage/doctor.svg';
import HealthRecordImage from '../img/homePage/health-record.svg';
import HealthVisitImage from '../img/homePage/health-visit.jpg';
import PharmacyImage from '../img/homePage/pharmacy.svg';
import PillImage from '../img/homePage/pill.svg';
import classes from './HomePage.module.css';

const carouselItems = [
  {
    img: <IconChecklist />,
    title: 'Welcome to PathCloud',
    description:
      'The secure, fast, global medical imaging platform.',
    url: '/get-care',
    label: 'Learn how we help',
  },
  {
    img: <IconChecklist />,
    title: 'Verify Email',
    description:
      'Please verify your email and enroll in 2-factor authentication.',
    url: '/account',
    label: 'Send verification email',
  },
  {
    img: <IconChecklist />,
    title: 'Update Your Preferences',
    description:
      'Setup your workflow and viewing preferences.',
    url: '/account/provider/choose-a-primary-care-povider',
    label: 'Setup preferences',
  },
  {
    img: <IconChecklist />,
    title: 'Schedule Training',
    description:
      'Schedule your 1-on-1 coadhing session',
    url: '/account',
    label: 'View available openings',
  },
];

const linkPages = [
  {
    img: HealthRecordImage,
    title: 'Clinical Pathology',
    description: '',
    href: '/health-record',
  },
  {
    img: PillImage,
    title: 'Precision Medicine Discovery',
    description: '',
    href: '/health-record/medications',
  },
  {
    img: PharmacyImage,
    title: 'Referral Network',
    description: 'Walgreens D2866 1363 Divisadero St  DIVISADERO',
    href: '#',
  },
];

const recommendations = [
  {
    title: 'Get travel health recommendations',
    description: 'Find out what vaccines and meds you need for your trip.',
  },
  {
    title: 'Get FSA/HSA reimbursement',
    description: 'Request a prescription for over-the-counter items.',
  },
  {
    title: 'Request health record',
    description: 'Get records sent to or from Foo Medical.',
  },
];

export function HomePage(): JSX.Element {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const profile = useMedplumProfile() as Patient | Practitioner;
  const profileName = profile.name ? formatHumanName(profile.name[0]) : '';

  return (
    <Box bg="gray.0">
      <Box className={classes.announcements}>
        {/* <span>
          TODO: Add scrolling pathology newsfeed <Anchor href="#"></Anchor>
        </span> */}
      </Box>
      <div className={classes.hero}>
        <Overlay
          gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.4) 40%)"
          opacity={1}
          zIndex={0}
        />
        <Container className={classes.heroContainer}>
          <Title className={classes.heroTitle}>
            Hi <span className="text-teal-600">{profileName}</span>!<br />
          </Title>
          <Button size="xl" radius="xl" className={classes.heroButton} onClick={() => navigate('/worklists/consultrequests')}>
            Recent Cases
          </Button>
        </Container>
      </div>
      <Box className={classes.callToAction}>
        {/* <Group justify="center">
          <IconAlertHexagon />
          <p>You have unsigned cases!</p>
          <Button variant="white" onClick={() => navigate('/messages')}>
            View Overdue Tasks
          </Button>
        </Group> */}
      </Box>
      <Box p="lg">
        <Container>
          <Grid>
            {carouselItems.map((item, index) => (
              <Grid.Col key={`card-${index}`} span={3} pb={40}>
                <Card shadow="md" radius="md" className={classes.card} p="xl">
                  <IconSquareCheck />
                  <Text size="lg" fw={500} mt="md">
                    {item.title}
                  </Text>
                  <Text size="sm" color="dimmed" my="sm">
                    {item.description}
                  </Text>
                  <Anchor>{item.label}</Anchor>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box p="lg">
        <Container>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <IconSquareCheck />
            <Text size="lg" fw={500} mt="md">
              Gestalt PathCloud Network
            </Text>
            <Text size="sm" color="dimmed" my="sm">
              The Gestalt PathCloud is a network of fellowship trained sub-specialized surgical pathologist, hematopathologists and cytologists from around the globe. 
              Consult with your peers to provide the best diagnosis for your patients
            </Text>
            <Group>
              <Button>Connect with a Peer</Button>
            </Group>
          </Card>
        </Container>
      </Box>
      <Box p="lg">
        <Container>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Flex>
              <Image src={HealthVisitImage} m="-40px 30px -40px -40px" w="40%" />
              <div>
                <Badge color={theme.primaryColor} size="xl">
                  Now available
                </Badge>
                <Text size="lg" fw={500} mt="md">
                  Gestalt AI Releases MitoDetect™ v.1.5
                </Text>
                <Text size="sm" color="dimmed" my="sm">
                  This algorithm is available today in the Gestalt PathCloud™ and for use on - Cutaneous mast cell tumor, breast cancer, lymphoma, lung cancer, melanoma, neuroendocrine, colon cancer, and bladder carcinoma. It has been trained on various models of Hamamatsu, Leica, 3D Histech and Aperio scanners
                </Text>
                <Anchor href="https://www.gestaltdiagnostics.com/now-available-gestalt-announces-ai-for-mitotic-counting">See Recent Article</Anchor>
              </div>
            </Flex>
          </Card>
        </Container>
      </Box>
      <Box p="lg">
        <Container>
          <Grid columns={3} pb="xl">
            {linkPages.map((item, index) => (
              <Grid.Col key={`card-${index}`} span={1}>
                <Card shadow="md" radius="md" className={classes.card} p="xl">
                  <Image src={item.img} w={80} />
                  <Text size="lg" fw={500} mt="md">
                    {item.title}
                  </Text>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* <Box p="lg">
        <Container>
          <Grid columns={2} pb="xl">
            <Grid.Col span={1}>
              <Card shadow="md" radius="md" className={classes.card} p="xl">
                <Group wrap="nowrap">
                  <Avatar src={DoctorImage} size="xl" />
                  <div>
                    <Text fw={500}>Primary Care Provider</Text>
                    <Text size="sm" color="dimmed" my="sm">
                      Having a consistent, trusted provider can lead to better health.
                    </Text>
                    <Button onClick={() => navigate('/account/provider')}>Choose Provider</Button>
                  </div>
                </Group>
              </Card>
            </Grid.Col>
            <Grid.Col span={1}>
              <Card shadow="md" radius="md" className={classes.card} p="xl">
                <Stack>
                  {recommendations.map((item, index) => (
                    <div key={`recommendation-${index}`}>
                      <Text fw={500}>{item.title}</Text>
                      <Text size="sm" color="dimmed" my="sm">
                        {item.description}
                      </Text>
                    </div>
                  ))}
                </Stack>
              </Card>
            </Grid.Col>
          </Grid>
        </Container>
      </Box> */}
    </Box>
  );
}
