/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Group, Text, rem } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone } from '@mantine/dropzone';
import { useWSIUploadList } from '../../context/WSIUploadListContext';

import { IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES } from '../../config';

interface IDicomUploadMetadata {
  metadata?: string; // File
  serverUrl: string;
}

export function WSIDropzone({ metadata, serverUrl }: IDicomUploadMetadata) {
  const { addUploadToList } = useWSIUploadList();

  const supportedTypesMessage = `Supported types: ${IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES.join(', ')}`;
  const defaultUploadMessage = 'Drag images or data container here or click to select files or containers';

  async function uploadFileAndMetaData(files: File[]) {
    files.map((file) => {
      addUploadToList(file, serverUrl, metadata);
    });
  }

  const children = (
    <>
      <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
        <Dropzone.Accept>
          <IconUpload style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }} stroke={1.5} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }} stroke={1.5} />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }} stroke={1.5} />
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            {defaultUploadMessage}
          </Text>
          <Text size="sm" c="dimmed" inline mt={7}>
            {supportedTypesMessage}
          </Text>
        </div>
      </Group>
    </>
  );

  return <Dropzone children={children} onDrop={uploadFileAndMetaData} />;
}
