import { BackgroundImage, Box, SimpleGrid } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { RegisterForm } from '../components/auth/v3.0.3/RegisterForm'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MEDPLUM_GOOGLE_CLIENT_ID, MEDPLUM_PROJECT_ID, MEDPLUM_RECAPTCHA_SITE_KEY } from '../config';
import RegisterBackgroundImg from '../../src/img/landingPage/laboratories+and+testing-1920w.jpg';

export function RegisterPage(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();

  useEffect(() => {
    if (medplum.getProfile()) {
      navigate('/signin');
    }
  }, [medplum, navigate]);

  return (
    <SimpleGrid cols={2}>
      <Box pt={100} pb={200}>
        <RegisterForm
          // type="project"
          clientId=''                       // Not using ClientId for PathCloud Registration
          projectId={MEDPLUM_PROJECT_ID}    // Must use PathCloud parent project ID to generate User in project
          googleClientId={MEDPLUM_GOOGLE_CLIENT_ID}
          recaptchaSiteKey={MEDPLUM_RECAPTCHA_SITE_KEY}
          onSuccess={() => navigate('/')}
        >
          <h2>Register with Gestalt PathCloud</h2>
        </RegisterForm>
      </Box>
      <BackgroundImage src={RegisterBackgroundImg} />
    </SimpleGrid>
  );
}
