import { PATHCLOUD_ORG_PUBLIC_01, MEDPLUM_PROJECT_ID } from '../../../config';

export enum WorklistID {
  PRIVATE_01 = 'private01',
  PUBLIC_01 = 'public01',
  PUBLIC_UNAUTHENTICATED = 'public_unauthenticated',
}

export enum DICOMViewer {
  PACS = 'PACS',
  CLINICAL = 'Clinical',
  GCP = 'GCP',
  AISTUDIO = 'AIStudio',
}

export type WorklistSettings = {
  id: WorklistID;
  name: string;
  title: string;
  orgid: string | undefined;
  queryFilter: string | undefined;
  showStudyUID: boolean;
  showPatient: boolean;
  showLaunchReport: boolean;
  showLaunchActions: boolean;
  showDICOMViewer: DICOMViewer[];
};

export const privateWorklistConfig: WorklistSettings = {
  id: WorklistID.PRIVATE_01,
  name: 'Pracitioner Org Default Worklist',
  title: 'My Archive',
  orgid: undefined,
  queryFilter: undefined,
  showStudyUID: false,
  showPatient: true,
  showLaunchReport: true,
  showLaunchActions: true,
  showDICOMViewer: [DICOMViewer.PACS, DICOMViewer.CLINICAL, DICOMViewer.AISTUDIO],
};

export const publicWorklistConfig: WorklistSettings = {
  id: WorklistID.PUBLIC_01,
  name: 'Public Org 1 Worklist',
  title: 'Public Archive 01',
  orgid: PATHCLOUD_ORG_PUBLIC_01,
  queryFilter: `(
        _count: 100
        _tag: "tags-imagingstudy-isPublic-${MEDPLUM_PROJECT_ID}|true",
      )`,
  showStudyUID: false,
  showPatient: true,
  showLaunchReport: false,
  showLaunchActions: false,
  showDICOMViewer: [DICOMViewer.PACS, DICOMViewer.CLINICAL, DICOMViewer.AISTUDIO],
};

export const publicWorklistUnAuthenticatedConfig: WorklistSettings = {
  id: WorklistID.PUBLIC_UNAUTHENTICATED,
  name: 'Public Org 1 Worklist',
  title: 'Public Archive 01',
  orgid: PATHCLOUD_ORG_PUBLIC_01,
  queryFilter: `(
        _count: 100
        _tag: "tags-imagingstudy-isPublic-${MEDPLUM_PROJECT_ID}|true",
      )`,
  showStudyUID: true,
  showPatient: false,
  showLaunchReport: false,
  showLaunchActions: false,
  showDICOMViewer: [DICOMViewer.PACS, DICOMViewer.CLINICAL],
};
