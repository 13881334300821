/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Title, Stack } from '@mantine/core';

import { WSIUploadItem } from './WSIUploadItem';
import { useWSIUploadList } from '../../context/WSIUploadListContext';

export function WSIUploadList() {
  const { uploadList, clearUploadList } = useWSIUploadList();

  return (
    <>
      {/* {uploadList.length !== 0 && ( */}
      {Array.isArray(uploadList) && (
        <div>
          <Title order={2} mb="md">
            WSI Upload List
          </Title>
          <Stack>
            {uploadList
              .slice()
              .reverse()
              .map((item) => {
                return <WSIUploadItem key={item.id} {...item} />;
              })}
          </Stack>
          <br />
          <Button onClick={clearUploadList}>Clear List</Button>
        </div>
      )}
    </>
  );
}
