import { Anchor, Container, Divider, SimpleGrid, Stack, Text } from '@mantine/core';
import classes from './Footer.module.css';

export function Footer(): JSX.Element {
  return (
    <footer className={classes.footer}>
      <div className={classes.inner}>
        <Container p="xl">
          <Stack gap="xl">
            <SimpleGrid cols={4}>
              <Anchor href="/">Getting started</Anchor>
              <Anchor href="/">PathCloud for Research</Anchor>
              <Anchor href="https://www.gestaltdiagnostics.com">Gestalt Website</Anchor>
              <Anchor href="/">Documentation</Anchor>
            </SimpleGrid>
            <Divider />
            <Text color="dimmed" size="sm">
              &copy; {new Date().getFullYear()} Gestalt Diagnostics, Inc. All rights reserved.
            </Text>
          </Stack>
        </Container>
      </div>
    </footer>
  );
}
