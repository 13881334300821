import { Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  formatSearchQuery,
  parseSearchDefinition,
  SearchRequest,
  normalizeErrorString
} from '@medplum/core';
import { UserConfiguration, ResourceType } from '@medplum/fhirtypes';
import { Loading, MemoizedSearchControl, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addSearchValues, getTransactionBundle, RESOURCE_TYPE_CREATION_PATHS, saveLastSearch } from './SearchPage.utils';
import classes from './SearchPage.module.css';

export function SearchPage(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<SearchRequest>();

  useEffect(() => {
    const parsedSearch = parseSearchDefinition(location.pathname + location.search);

    const populatedSearch = addSearchValues(parsedSearch, medplum.getUserConfiguration());

    if (
      location.pathname === `/${populatedSearch.resourceType}` &&
      location.search === formatSearchQuery(populatedSearch)
    ) {
      saveLastSearch(populatedSearch);
      setSearch(populatedSearch);
    } else {
      navigate(`/${populatedSearch.resourceType}${formatSearchQuery(populatedSearch)}`);
    }
  }, [medplum, navigate, location]);

  if (!search?.resourceType || !search.fields || search.fields.length === 0) {
    return <Loading />;
  }

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <MemoizedSearchControl
        checkboxesEnabled={true}
        search={search}
        onNew={() => {
          navigate(RESOURCE_TYPE_CREATION_PATHS[search.resourceType] ?? `/${search.resourceType}/new`);
        }}
        onClick={(e) => navigate(`/${e.resource.resourceType}/${e.resource.id}`)}
        onAuxClick={(e) => window.open(`/${e.resource.resourceType}/${e.resource.id}`, '_blank')}
        onChange={(e) => {
          navigate(`/${search.resourceType}${formatSearchQuery(e.definition)}`);
        }}
        onDelete={(ids: string[]) => {
          if (window.confirm('Are you sure you want to delete these resources?')) {
            medplum.invalidateSearches(search.resourceType as ResourceType);
            medplum
              .executeBatch({
                resourceType: 'Bundle',
                type: 'batch',
                entry: ids.map((id) => ({
                  request: {
                    method: 'DELETE',
                    url: `${search.resourceType}/${id}`,
                  },
                })),
              })
              .then(() => setSearch({ ...search }))
              .catch((err) => showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false }));
          }
        }}
      />
    </Paper>
  );
}

