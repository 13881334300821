import { Box, Stack, Title } from '@mantine/core';
import { getReferenceString } from '@medplum/core';
import { Patient } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { InfoButton } from '../../components/InfoButton';
import { InfoSection } from '../../components/InfoSection';

export function UtilizationAndBilling(): JSX.Element {
  const medplum = useMedplum();
  const patient = medplum.getProfile() as Patient;
  const coverages = medplum.searchResources('Coverage', 'patient=' + getReferenceString(patient)).read();
  const payments = medplum.searchResources('PaymentNotice').read();

  return (
    <Box p="xl">
      <Title mb="xl">Utilization & Credits</Title>
      <InfoSection title="Storage Utilization">
        {coverages.length === 0 ? (
          <Box p="xl">Current storage under quota</Box>
        ) : (
          <Stack gap={0}>
            {coverages.map((c) => (
              <InfoButton key={c.id}>{c.id}</InfoButton>
            ))}
          </Stack>
        )}
      </InfoSection>
      <InfoSection title="Credits">
        {payments.length === 0 ? (
          <Box p="xl">No credits accrued.</Box>
        ) : (
          <Stack gap={0}>
            {payments.map((p) => (
              <InfoButton key={p.id}>{p.id}</InfoButton>
            ))}
          </Stack>
        )}
      </InfoSection>
    </Box>
  );
}
