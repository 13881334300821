import { Box, Title } from '@mantine/core';
import { Task } from '@medplum/fhirtypes';
import { ResourceTable, useMedplum } from '@medplum/react';
import { useParams } from 'react-router-dom';
import { InfoSection } from '../../components/InfoSection';

export function TaskItem(): JSX.Element {
  const medplum = useMedplum();
  const { itemId } = useParams();
  const resource: Task = medplum.readResource('Task', itemId as string).read();

  return (
    <Box p="xl">
      <Title order={2} mb="md">
        
        {
          // @ts-expect-error: Task coming back as bundle
          Array.isArray(resource.entry[0].resource.code?.coding) && resource.entry[0].resource.code.coding[0].display
        }
      </Title>
      <InfoSection title="Action Item">
        <ResourceTable 
          value={
            // @ts-expect-error: Task coming back as bundle
            resource.entry[0].resource
          } 
          ignoreMissingValues 
        />
      </InfoSection>
    </Box>
  );
}
