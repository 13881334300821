/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useMemo } from 'react';
import { MedplumClient } from '@medplum/core';
import { useNavigate  } from 'react-router-dom';
import { MEDPLUM_BASE_URL, PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET } from '../config'
export interface MedplumPathcloudProps {
  readonly medplum: MedplumClient;
  readonly clientId: string;
  readonly clientSecret: string;
}

export function useMedplumPathcloudClient(): MedplumClient {
  const navigate = useNavigate();

  const newClient = useMemo(() => {
    const pathcloudMedplum = new MedplumClient({
      baseUrl: MEDPLUM_BASE_URL,
      cacheTime: 60000,
      autoBatchTime: 100,
      clientId: PATHCLOUD_PROJECT_CLIENT_ID,
      clientSecret: PATHCLOUD_PROJECT_CLIENT_SECRET,
      onUnauthenticated: () => navigate('/signin'),
    });
    return pathcloudMedplum
  }, [navigate]);
  
  useEffect( () => {}, [newClient]);
  
  return newClient
}