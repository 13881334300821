import { Questionnaire } from '@medplum/fhirtypes';
const reportdate = Date().toString();

export const defaultDiagnosticReportQuestionnaire: Questionnaire = {
  resourceType: 'Questionnaire',
  name: 'PathologyReportTemplate01',
  title: 'Pathology Report',
  status: 'active',
  id: 'b0166b1b-d4b8-4d09-b6bc-2f094cf7de9e',
  item: [
    {
      id: 'id-55',
      linkId: 'date',
      type: 'date',
      text: 'Date of Visit',
      initial: [
        {
          valueDate: reportdate,
        },
      ],
    },
    {
      id: 'id-64',
      linkId: 'g10',
      type: 'group',
      text: 'Clinical Description',
      item: [
        {
          id: 'id-67',
          linkId: 'q13',
          type: 'open-choice',
          text: 'Self-reported history',
          answerOption: [
            {
              id: 'id-76',
              valueString: 'Blood clots',
            },
            {
              id: 'id-77',
              valueString: 'Stroke',
            },
            {
              id: 'id-78',
              valueString: 'Breast cancer',
            },
            {
              id: 'id-80',
              valueString: 'Irregular bleeding',
            },
            {
              id: 'id-81',
              valueString: 'BMI > 30',
            },
          ],
        },
        {
          id: 'id-100',
          linkId: 'q34',
          type: 'text',
          text: 'Details',
        },
      ],
    },
    {
      id: 'id-68',
      linkId: 'g14',
      type: 'group',
      text: 'Gross Description',
      item: [
        {
          id: 'id-111',
          linkId: 'q134',
          type: 'text',
          text: 'Details',
        },
      ],
    },
    {
      id: 'id-71',
      linkId: 'g17',
      type: 'group',
      text: 'Microscopic Description',
      item: [
        {
          id: 'id-72',
          linkId: 'q18',
          type: 'text',
          text: 'Details',
        }
      ],
    },
    {
      id: 'id-86',
      linkId: 'g21',
      type: 'group',
      text: 'Diagnosis',
      item: [
        {
          id: 'id-87',
          linkId: 'q22',
          type: 'text',
          text: 'Details',
        },
      ],
    },
    {
      id: 'id-87',
      linkId: 'g22',
      type: 'group',
      text: 'Billing',
      item: [
        {
          id: 'id-103',
          linkId: 'q37',
          type: 'choice',
          text: 'Choose a code for this visit',
          answerOption: [
            {
              id: 'id-104',
              valueString: '88302 (Level II)',
            },
            {
              id: 'id-105',
              valueString: '88304(Level III)',
            },
            {
              id: 'id-106',
              valueString: '88305(Level IV)',
            },
          ],
        },
      ],
    },
  ],
  subjectType: ['Patient'],
};
