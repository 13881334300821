/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactNode, useContext, createContext, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  IMAGE_TRANSFORM_SVC_URL,
  IMAGE_TRANSFORM_SVC_MAX_UPLOAD_SIZE,
  IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES,
} from '../config';

type UploadState = 'Initialized' | 'Uploading' | 'Conversion' | 'Finished' | 'Error' | 'Unknown';

export interface UploadItem {
  id: string;
  serverUrl: string;
  metadata?: string;
  progress: number;
  state: UploadState;
  errorMessages: string[];
  history: string[];
  StudyUID?: string;
  SeriesUID?: string[];
  results?: {
    StudyInstanceUID: '';
    SeriesInstances: [
      {
        SeriesInstanceUID: '';
        Instances: [
          {
            SOPInstanceUID: '';
          },
        ];
      },
    ];
  };
  intervalId?: NodeJS.Timeout;
  task_id?: string;
}

interface WSIUploadListContextProps {
  uploadList: UploadItem[];
  getUploadProgress: (id: string) => number;
  getUploadState: (id: string) => UploadState;
  getUploadErrorMessages: (id: string) => string[];
  getUploadHistory: (id: string) => string[];
  addUploadToList: (file: File, serverUrl: string, metadata?: string) => void;
  removeUploadFromList: (id: string) => void;
  clearUploadList: () => void;
}

const WSIUploadListContext = createContext<WSIUploadListContextProps>({} as WSIUploadListContextProps);

export const useWSIUploadList = () => useContext(WSIUploadListContext);

export const WSIUploadListProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [uploadList, setUploadList] = useState<UploadItem[]>([]);
  const supportedImageTypesError = `Supported types: ${IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES.join(', ')}`;

  const createLogMessage = (message: string) => `${new Date().toLocaleTimeString()} | ${message}`;

  const addUploadToList = (file: File, serverUrl: string, metadata?: string) => {
    const newUploadItem: UploadItem = {
      id: file.name,
      serverUrl,
      metadata,
      progress: 0,
      state: 'Initialized',
      errorMessages: [],
      history: [createLogMessage('Initialized')],
    };

    // Validation checks for file type and size
    validateFile(file, newUploadItem);

    setUploadList((prevList) => [...prevList, newUploadItem]);

    if (newUploadItem.state !== 'Error') {
      void uploadFile(file, newUploadItem, serverUrl, metadata);
    }
  };

  const validateFile = (file: File, uploadItem: UploadItem) => {
    if (!IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES.some((ext: string) => file.name.toLowerCase().endsWith(ext))) {
      uploadItem.state = 'Error';
      uploadItem.errorMessages.push(createLogMessage(`Error: Unsupported file type. ${supportedImageTypesError}`));
    }

    if (file.size > IMAGE_TRANSFORM_SVC_MAX_UPLOAD_SIZE) {
      uploadItem.state = 'Error';
      uploadItem.errorMessages.push(
        createLogMessage(`Error: File size exceeds ${IMAGE_TRANSFORM_SVC_MAX_UPLOAD_SIZE / 1024 ** 3}GB.`)
      );
    }

    if (uploadList.some((item) => item.id === uploadItem.id)) {
      uploadItem.id = uploadItem.id + '_Copy';
      uploadItem.state = 'Error';
      uploadItem.errorMessages.push(createLogMessage('Error: File already uploaded.'));
    }
  };

  const updateUploadItem = (id: string, updates: Partial<UploadItem>) => {
    setUploadList((currentItems) =>
      currentItems.map((item) => {
        if (item.id === id) {
          // Determine if the state has changed and if there is a new history message to add
          const shouldUpdateHistory = updates.state && updates.state !== item.state && updates.history;

          // Update the history only if the state has changed and there's a new history message
          const updatedHistory = shouldUpdateHistory
            ? [...item.history, ...(updates.history as string[])] // Append new history messages to existing ones
            : item.history;

          // Append new error messages to existing ones if any
          const updatedErrorMessages = updates.errorMessages
            ? [...item.errorMessages, ...updates.errorMessages]
            : item.errorMessages;

          // Prepare the final updates, excluding the history update if not required
          const finalUpdates = { ...updates, errorMessages: updatedErrorMessages };
          if (shouldUpdateHistory) {
            finalUpdates.history = updatedHistory;
          }

          // Apply the final updates
          return { ...item, ...finalUpdates };
        }
        return item;
      })
    );
  };

  const handleUploadProgress = (id: string, progressEvent: ProgressEvent) => {
    const { loaded, total } = progressEvent;
    const percentage = Math.floor((loaded * 100) / total);
    const newState = total === loaded ? 'Conversion' : 'Uploading';
    const newHistoryMessage = total === loaded ? 'Conversion to DICOM in initialized...' : 'Uploading...';

    updateUploadItem(id, {
      progress: percentage,
      state: newState,
      history: [createLogMessage(newHistoryMessage)],
    });
  };

  const pollConversionStatus = (id: string, taskId: string) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`${IMAGE_TRANSFORM_SVC_URL}/task/${taskId}`);
        const { data } = response;
        if (data.task_status === 'SUCCESS') {
          clearInterval(intervalId);
          updateUploadItem(id, {
            state: 'Finished',
            progress: 100,
            history: [createLogMessage('Upload and conversion finished')],
            results: data.task_result,
          });
        } else if (data.task_status === 'FAILURE') {
          updateUploadItem(id, {
            state: 'Error',
            progress: 100,
            errorMessages: [createLogMessage(`Error: ${data.task_error}`)],
          });
          clearInterval(intervalId);
        } else {
          updateUploadItem(id, {
            state: 'Conversion',
            history: [createLogMessage(`Conversion Status: ${data.task_status}`)],
          });
        }
      } catch (error) {
        updateUploadItem(id, {
          state: 'Error',
          progress: 100,
          errorMessages: [createLogMessage(`Error occurred during polling: ${error}`)],
        });
        clearInterval(intervalId);
      }
    }, 5000);

    updateUploadItem(id, { intervalId });
  };

  const handleUploadResponse = async (id: string, response: AxiosResponse) => {
    if (response.status === 200) {
      updateUploadItem(id, {
        state: 'Finished',
        progress: 100,
        history: [createLogMessage('Upload and conversion finished')],
        results: response.data,
      });
    } else if (response.status === 202) {
      pollConversionStatus(id, response.data.task_id);
    } else {
      updateUploadItem(id, {
        state: 'Error',
        progress: 100,
        errorMessages: [createLogMessage(`Error: ${response.statusText} | ${response.data}`)],
      });
    }
  };

  const handleUploadError = (id: string, error: any) => {
    const errorMessage = `Error: ${error.message} | ${(error.response?.data.detail) || 'Unknown error'}`;
    updateUploadItem(id, { state: 'Error', progress: 100, errorMessages: [createLogMessage(errorMessage)] });
  };

  const uploadFile = async (file: File, uploadItem: UploadItem, serverUrl: string, metadata?: string) => {
    const formData = new FormData();
    formData.append('file', file);
    if (metadata) {
      const metadataFile = new File([metadata], 'metadata.json', { type: 'application/json' });
      formData.append('dataset', metadataFile);
    }

    const options = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent: ProgressEvent) => handleUploadProgress(uploadItem.id, progressEvent),
    };

    try {
      // @ts-expect-error: option parameter works; error not helpful
      const response = await axios.put(serverUrl, formData, options);
      void handleUploadResponse(uploadItem.id, response);
    } catch (error) {
      handleUploadError(uploadItem.id, error);
    }
  };

  const getUploadProgress = (id: string): number => uploadList.find((item) => item.id === id)?.progress || 0;
  const getUploadState = (id: string): UploadState => uploadList.find((item) => item.id === id)?.state || 'Unknown';
  const getUploadErrorMessages = (id: string): string[] =>
    uploadList.find((item) => item.id === id)?.errorMessages || [];
  const getUploadHistory = (id: string): string[] => uploadList.find((item) => item.id === id)?.history || [];

  const removeUploadFromList = (id: string) => setUploadList((prevList) => prevList.filter((item) => item.id !== id));
  const clearUploadList = () => setUploadList([]);

  return (
    <WSIUploadListContext.Provider
      value={{
        uploadList,
        getUploadProgress,
        getUploadState,
        getUploadErrorMessages,
        getUploadHistory,
        addUploadToList,
        removeUploadFromList,
        clearUploadList,
      }}
    >
      {children}
    </WSIUploadListContext.Provider>
  );
};
