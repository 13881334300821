/**
 * Modified from packages/react/ResourceForm (Medplum:3.1.8 - 090524)
 * Used for displaying partial list of elements from a single Resource
 * Enables modification of element Title and Description
*/
import { Alert, Button, Group, Stack, TextInput } from '@mantine/core';
import {
  AccessPolicyInteraction,
  applyDefaultValuesToResource,
  canWriteResourceType,
  isPopulated,
  satisfiedAccessPolicy,
  tryGetProfile,
  InternalSchemaElement, 
  getElementDefinition,
  TypedValue,
  getPathDisplayName
} from '@medplum/core';
import { OperationOutcome, Reference, Resource } from '@medplum/fhirtypes';
import { useMedplum, useResource } from '@medplum/react-hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { FormSection, ResourcePropertyInput, setPropertyValue } from '@medplum/react';
import { IconAlertCircle } from '@tabler/icons-react';
import { getValueAndTypeFromElement } from './ResourcePropertyDisplay.utils';

export interface ResourceElementKeys {
  key: string;
  title: string;
  description: string;
}

export interface ResourcePartialFormProps {
  readonly defaultValue: Partial<Resource> | Reference;
  readonly outcome?: OperationOutcome;
  readonly onSubmit: (resource: Resource) => void;
  readonly onDelete?: (resource: Resource) => void;
  readonly schemaName?: string;
  /** (optional) URL of the resource profile used to display the form. Takes priority over schemaName. */
  readonly profileUrl?: string;
  readonly resourceElementKeys: ResourceElementKeys[];
}

export function ResourcePartialForm(props: ResourcePartialFormProps): JSX.Element {
  const { outcome } = props;
  const medplum = useMedplum();
  const defaultValue = useResource(props.defaultValue);
  const [schemaLoaded, setSchemaLoaded] = useState<string>();
  const [value, setValue] = useState<Resource>();
  const accessPolicy = medplum.getAccessPolicy();

  const typedValue: TypedValue = { type: schemaLoaded as string, value };
  function setValueWrapper(newValue: any): void {
    setValue(newValue);
  }
  useEffect(() => {
    if (defaultValue) {
      if (props.profileUrl) {
        const profileUrl: string = props.profileUrl;
        medplum
          .requestProfileSchema(props.profileUrl, { expandProfile: true })
          .then(() => {
            const profile = tryGetProfile(profileUrl);
            if (profile) {
              setSchemaLoaded(profile.name);
              const modifiedDefaultValue = applyDefaultValuesToResource(defaultValue, profile);
              setValue(modifiedDefaultValue);
            } else {
              console.error(`Schema not found for ${profileUrl}`);
            }
          })
          .catch((reason) => {
            console.error('Error in requestProfileSchema', reason);
          });
      } else {
        const schemaName = props.schemaName ?? defaultValue?.resourceType;
        medplum
          .requestSchema(schemaName)
          .then(() => {
            setValue(defaultValue);
            setSchemaLoaded(schemaName);
          })
          .catch(console.log);
      }
    }
  }, [medplum, defaultValue, props.schemaName, props.profileUrl]);

  const accessPolicyResource = useMemo(() => {
    return defaultValue && satisfiedAccessPolicy(defaultValue, AccessPolicyInteraction.READ, accessPolicy);
  }, [accessPolicy, defaultValue]);

  const canWrite = useMemo<boolean>(() => {
    if (medplum.isSuperAdmin()) {
      return true;
    }

    if (!accessPolicy) {
      return true;
    }

    if (!isPopulated(value?.resourceType)) {
      return true;
    }

    return canWriteResourceType(accessPolicy, value?.resourceType);
  }, [medplum, accessPolicy, value?.resourceType]);

  if (!schemaLoaded || !value) {
    return <div>Loading...</div>;
  }

  if (!canWrite) {
    return (
      <Alert color="red" title="Permission denied" icon={<IconAlertCircle />}>
        Your access level prevents you from editing and creating {value.resourceType} resources.
      </Alert>
    );
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        if (props.onSubmit) {
          props.onSubmit(value);
        }
      }}
    >
      <Stack mb="xl">
        
        <FormSection title="ID" htmlFor="id" outcome={outcome}>
          <TextInput name="id" defaultValue={value.id} disabled={true} />
        </FormSection>
        {props.resourceElementKeys.map((resourceElement) => {
          const element = getElementDefinition(schemaLoaded, resourceElement.key) as InternalSchemaElement
          const [propertyValue, propertyType] = getValueAndTypeFromElement(typedValue, resourceElement.key, element);
          const required = element.min !== undefined && element.min > 0;
          const valuePath = schemaLoaded + '.' + resourceElement.key || undefined;
          const resourcePropertyInput = (
            <ResourcePropertyInput
              key={resourceElement.key}
              property={element}
              name={resourceElement.key}
              path={schemaLoaded + '.' + resourceElement.key}
              valuePath={valuePath}
              defaultValue={propertyValue}
              defaultPropertyType={propertyType}
              onChange={(newValue: any, propName?: string) => {
                setValueWrapper(setPropertyValue({ ...value }, resourceElement.key, propName ?? resourceElement.key, element, newValue));
              }}
              outcome={props.outcome}
            />
          );
          return (
            <FormSection
              key={resourceElement.key}
              // title={getPathDisplayName(resourceElement.key)}
              title={resourceElement.title !== '' ? resourceElement.title : getPathDisplayName(resourceElement.key)}
              description={element.description}
              withAsterisk={required}
              htmlFor={resourceElement.key}
              outcome={props.outcome}
              fhirPath={element.path}
              errorExpression={valuePath}
            >
              {resourcePropertyInput}
            </FormSection>
          );
        })}
        <FormSection title="Resource Type" htmlFor="resourceType" outcome={outcome}>
          <TextInput name="resourceType" defaultValue={value.resourceType} disabled={true} />
        </FormSection>
      </Stack>
      <Group justify="flex-end" mt="xl">
        <Button type="submit">OK</Button>
        {props.onDelete && (
          <Button
            variant="outline"
            color="red"
            type="button"
            onClick={() => {
              (props.onDelete as (resource: Resource) => void)(value);
            }}
          >
            Delete
          </Button>
        )}
      </Group>
    </form>
  );
}
