import { Box, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { formatDate, getReferenceString } from '@medplum/core';
import { Patient } from '@medplum/fhirtypes';
import { StatusBadge, useMedplum } from '@medplum/react';
import { IconCalendar } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { usePatient } from '../../hooks/usePatient';
import { InfoButton } from '../../components/InfoButton';
import { InfoSection } from '../../components/InfoSection';

export function TasksTab(): JSX.Element {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const medplum = useMedplum();
  const patient = usePatient() as Patient;
  const tasks = medplum.searchResources('Task', 'subject=' + getReferenceString(patient)).read();


  return (
    <Box p="xl">
      {/* <Title mb="lg">Action Items</Title> */}
      <InfoSection title="Tasks">
        <Stack gap={0}>
          {tasks.map((resource) => (
            <InfoButton key={resource.id} onClick={() => navigate(`./${resource.id}`)}>
              <div>
                <Text c={theme.primaryColor} fw={500}>
                  {Array.isArray(resource.code?.coding) && resource.code.coding[0].display}
                </Text>
                <Text mt="sm" c="gray.5" style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <IconCalendar size={16} />
                  <time>{formatDate(resource.executionPeriod?.start)} </time>
                  {resource.executionPeriod?.end && <time>&nbsp;-&nbsp;{formatDate(resource.executionPeriod?.end)}</time>}
                </Text>
              </div>
              <StatusBadge status={resource.status as string} />
            </InfoButton>
          ))}
        </Stack>
      </InfoSection>
    </Box>
  );
}
