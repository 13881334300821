import {
  Anchor,
  Box,
  Burger,
  Button,
  Center,
  Collapse,
  Container,
  Divider,
  Drawer,
  Group,
  HoverCard,
  AppShell,
  ScrollArea,
  SimpleGrid,
  Text,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconBook,
  IconChartPie3,
  IconChevronDown,
  IconCode,
  IconCoin,
  IconFingerprint,
  IconNotification,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../components/Logo';
import classes from './Header.module.css';

const headerdropdowndata = [
  {
    icon: IconCode,
    title: 'Digital Pathology',
    description: 'Access your images anywhere at anytime',
  },
  {
    icon: IconCoin,
    title: 'Simple Fees',
    description: 'Pay for what you use at competitive rates',
  },
  {
    icon: IconFingerprint,
    title: 'Secure',
    description: 'Secure data storage with 2-factor authentication',
  },
  {
    icon: IconBook,
    title: 'Documentation',
    description: 'Simple solution that you can support yourself',
  },
  {
    icon: IconNotification,
    title: 'Industry Standards',
    description: 'Using industry standards for medical image and structured healthacare data',
  },
  {
    icon: IconChartPie3,
    title: 'Analytics',
    description: 'Get the big picture of how your patients and your practice are doing',
  },
];

export function Header(): JSX.Element {
  const navigate = useNavigate();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();

  const links = headerdropdowndata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group wrap="nowrap" align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon style={{ width: rem(22), height: rem(22) }} color={theme.primaryColor} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" c="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <>
      <AppShell.Header px="md">
        <Container h="100%">
          <Group justify="space-between" h="100%">
            <UnstyledButton className={classes.logoButton} onClick={() => navigate('/')}>
              <Logo width={240} />
            </UnstyledButton>

            <Group style={{ height: '100%' }} gap={0} className={classes.hiddenMobile}>
              <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                <HoverCard.Target>
                  <a href="#" className={classes.link}>
                    <Center inline>
                      <Box component="span" mr={5}>
                        Services
                      </Box>
                      <IconChevronDown size={16} />
                    </Center>
                  </a>
                </HoverCard.Target>

                <HoverCard.Dropdown style={{ overflow: 'hidden' }}>
                  <Group justify="space-between" px="md">
                    <Text fw={500}>Services</Text>
                    <Anchor href="#" size="xs">
                      View all
                    </Anchor>
                  </Group>

                  <Divider my="sm" mx="-md" />

                  <SimpleGrid cols={2} spacing={0}>
                    {links}
                  </SimpleGrid>

                  <div className={classes.dropdownFooter}>
                    <Group justify="space-between">
                      <div>
                        <Text fw={500} size="sm">
                          Get started today!
                        </Text>
                        <Text size="xs" color="dimmed">
                          Register and start adding images to your PathCloud
                        </Text>
                      </div>
                      <Button variant="default" onClick={() => navigate('/register')}>Get started</Button>
                    </Group>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
              <a href="/worklists/publicworklist" className={classes.link}>
                PathCloud Public
              </a>
            </Group>

            <Group className={classes.hiddenMobile}>
              <Button variant="default" onClick={() => navigate('/signin')}>
                Log in
              </Button>
              <Button onClick={() => navigate('/register')}>Sign up</Button>
            </Group>

            <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          </Group>
        </Container>
      </AppShell.Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea style={{ height: 'calc(100vh - 60px)' }} mx="-md">
          <Divider my="sm" />

          <a href="#" className={classes.link}>
            Home
          </a>
          <UnstyledButton className={classes.link} onClick={toggleLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                Features
              </Box>
              <IconChevronDown size={16} />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>{links}</Collapse>
          <a href="#" className={classes.link}>
            Learn
          </a>
          <a href="#" className={classes.link}>
            Academy
          </a>

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md">
            <Button variant="default" onClick={() => navigate('/signin')}>
              Log in
            </Button>
            <Button onClick={() => navigate('/register')}>Sign up</Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
}
