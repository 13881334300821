import { StatusBadge, useMedplum } from '@medplum/react';
import { type MRT_ColumnDef } from 'mantine-react-table';
import { ImagingStudyList } from '../types';
import { Box, Text, ActionIcon, Tooltip, Menu } from '@mantine/core';
import { IconReportMedical, IconShare, IconEdit, IconPolaroid } from '@tabler/icons-react';
import { DICOMViewer, WorklistID, WorklistSettings } from './configs';
import {
  PATHCLOUD_DICOMSTORE,
  PATHCLOUD_DICOMSTORE_URL,
  PATHCLOUD_DICOMSTORE_PUBLIC,
  PATHCLOUD_DICOMSTORE_URL_PUBLIC,
  PATHCLOUD_VIEWER_ROOT_URL_AISTUDIO,
  PATHCLOUD_VIEWER_ROOT_URL_CLINICAL,
  PATHCLOUD_VIEWER_ROOT_URL_CLINICAL_PUBLIC,
  PATHCLOUD_VIEWER_ROOT_URL_GCP,
  PATHCLOUD_VIEWER_ROOT_URL_PACS,
} from '../../../config';

export const getStudyUIDColumn = (): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn: (row) => row.identifier[0].value.replace('urn:oid:', '') ?? 'Unknown',
  id: 'studyUID',
  header: 'StudyUID',
});

export const getPatientNameColumn = (): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn: (row) =>
    `${row?.subj_Patient?.Patient?.name?.[0]?.given?.[0] ?? ''} ${row?.subj_Patient?.Patient?.name?.[0]?.family ?? ''}`,
  accessorKey: 'patientName',
  id: 'patientName',
  header: 'Patient',
  size: 170,
  Cell: ({ renderedCellValue }) => (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Text>{renderedCellValue}</Text>
    </Box>
  ),
});

export const getModalityColumn = (): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn: (row) => row.series[0].modality.code ?? 'Unknown',
  id: 'modality',
  header: 'Modality',
  size: 122,
  Cell: ({ row }) => (
    <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      {row.original.series?.[0]?.modality?.code === 'SM' ? 'Microscopy' : row.original.series?.[0]?.modality?.code}
    </Box>
  ),
});

export const getStudyDescriptionColumn = (): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn: (row) => row.description ?? 'NA',
  id: 'studyDescription',
  header: 'Study Description',
  size: 180,
});

export const getLocationColumn = (): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn: (row) => row.location?.display ?? 'Unknown',
  id: 'location',
  header: 'Locations',
  size: 180,
});

export const getStudyStatusColumn = (): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn: (row) => row.imageStatus ?? 'Unknown',
  id: 'studyStatus',
  header: 'Status',
  size: 132,
  Cell: ({ row }) => (
    <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <StatusBadge status={row.original.imageStatus as string} />
    </Box>
  ),
});

export const getDateColumn = (
  id: string,
  header: string,
  accessorFn: (row: ImagingStudyList) => Date | undefined
): MRT_ColumnDef<ImagingStudyList> => ({
  accessorFn,
  id,
  header,
  size: 130,
  filterVariant: 'date-range',
  sortingFn: 'datetime',
  enableGrouping: false,
  enableColumnFilterModes: false,
  Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString() ?? 'N/A',
  Header: ({ column }) => <em>{column.columnDef.header}</em>,
});

export const getLaunchReportColumn = (
  open: () => void,
  setDiagnosticReportId: (id: string) => void,
  setPatientId: (id: string) => void
): MRT_ColumnDef<ImagingStudyList> => ({
  id: 'launchReport',
  header: 'Report',
  enableGrouping: false,
  enableSorting: false,
  enableColumnActions: false,
  size: 70,
  Cell: ({ row }) => (
    <ActionIcon
      color="clear"
      onClick={() => {
        open();
        setDiagnosticReportId(
          row?.original?.servicerequest?.[0]?.ServiceRequest?.diagnosticreport?.[0]?.id?.toString() ?? ''
        );
        setPatientId(row?.original?.subj_Patient?.Patient?.id?.toString() ?? '');
      }}
    >
      <IconReportMedical size={25} color="green" />
    </ActionIcon>
  ),
});

export const getLaunchActionsColumn = (handlerShare: any): MRT_ColumnDef<ImagingStudyList> => ({
  id: 'launchActions',
  header: 'Actions',
  enableGrouping: false,
  enableSorting: false,
  enableColumnActions: false,
  size: 70,
  Cell: () => (
    <Box>
      <ActionIcon color="clear" onClick={() => handlerShare.open()}>
        <Tooltip label="Share">
          <IconShare size={25} color="gray" />
        </Tooltip>
      </ActionIcon>
      <ActionIcon color="clear">
        <Tooltip label="Edit">
          <IconEdit size={25} color="gray" />
        </Tooltip>
      </ActionIcon>
    </Box>
  ),
});

export const openViewer = (
  viewerType: string,
  modalityCode: string | undefined,
  seriesUid: string | undefined,
  studyInstanceUid: string,
  worklistSettings: WorklistSettings,
  userHint: string
) => {
  const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(window.innerHeight * 0.75)}`;

  let url = '';

  switch (viewerType) {
    case DICOMViewer.AISTUDIO:
      if (modalityCode === 'SM') {
        let dcm_store_url =
          worklistSettings.id === WorklistID.PRIVATE_01
            ? `${PATHCLOUD_DICOMSTORE_URL}/${PATHCLOUD_DICOMSTORE}`
            : `${PATHCLOUD_DICOMSTORE_URL_PUBLIC}/${PATHCLOUD_DICOMSTORE_PUBLIC}`;

        url = `${PATHCLOUD_VIEWER_ROOT_URL_AISTUDIO}/${studyInstanceUid}/${seriesUid}/?server=${dcm_store_url}`;
      }
      break;
    case DICOMViewer.PACS:
      if (modalityCode === 'SM') {
        url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/microscopy?StudyInstanceUIDs=${studyInstanceUid}&${userHint}`;
      } else {
        url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/viewer?StudyInstanceUIDs=${studyInstanceUid}&${userHint}`;
      }
      break;
    case DICOMViewer.CLINICAL:
      const clinicalUrl =
        worklistSettings.id === WorklistID.PRIVATE_01
          ? PATHCLOUD_VIEWER_ROOT_URL_CLINICAL
          : PATHCLOUD_VIEWER_ROOT_URL_CLINICAL_PUBLIC;
      if (modalityCode === 'SM') {
        url = `${clinicalUrl}/studies/${studyInstanceUid}/series/${seriesUid}?${userHint}`;
      }
      break;
    case DICOMViewer.GCP:
      if (modalityCode === 'SM') {
        url = `${PATHCLOUD_VIEWER_ROOT_URL_GCP}?series=${PATHCLOUD_DICOMSTORE}/studies/${studyInstanceUid}/series/${seriesUid}&${userHint}`;
      }
      break;
    default:
      break;
  }

  if (url) {
    console.log('Opening viewer:', url);
    window.open(url, `${viewerType}-PathCloud`, windowFeatures);
  }
};

export const renderViewerMenuItem = (
  viewerType: string,
  iconColor: string,
  label: string,
  modalityCode: string | undefined,
  seriesUid: string | undefined,
  studyInstanceUid: string,
  worklistSettings: WorklistSettings,
  userHint: string
) => (
  <Menu.Item
    onClick={() => openViewer(viewerType, modalityCode, seriesUid, studyInstanceUid, worklistSettings, userHint)}
  >
    <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
      <IconPolaroid size={25} color={iconColor} />
      <Text fw={500} ta="center">
        {label}
      </Text>
    </Box>
  </Menu.Item>
);
