import {
  Avatar,
  Group,
  MantineColorScheme,
  Menu,
  SegmentedControl,
  Stack,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { ProfileResource, getReferenceString } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { useMedplumContext } from '@medplum/react-hooks';
import { IconLogout, IconLogin, IconSettings, IconSwitchHorizontal } from '@tabler/icons-react';
import { ResourceAvatar, HumanNameDisplay } from '@medplum/react';


export interface HeaderDropdownProps {
  readonly version?: string;
}

export function HeaderDropdown(props: HeaderDropdownProps): JSX.Element {
  const context = useMedplumContext();
  const { medplum, profile, navigate } = context;
  const logins = medplum.getLogins();
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  console.log('resourceType=', medplum.getProfile()?.resourceType)
  return (
    <>
      {profile ? (
            <Menu.Dropdown>
              <Stack align="center" p="xl">
                <ResourceAvatar size="xl" radius={100} value={context.profile} />
                <HumanNameDisplay value={context.profile?.name?.[0] as HumanName} />
                <Text c="dimmed" size="xs">
                  {medplum.getActiveLogin()?.project.display}
                </Text>
              </Stack>
              {logins.length > 1 && <Menu.Divider />}
              {logins.map(
                (login) =>
                  login.profile.reference !== getReferenceString(context.profile as ProfileResource) && (
                    <Menu.Item
                      key={login.profile.reference}
                      onClick={() => {
                        medplum
                          .setActiveLogin(login)
                          .then(() => window.location.reload())
                          .catch(console.log);
                      }}
                    >
                      <Group>
                        <Avatar radius="xl" />
                        <div style={{ flex: 1 }}>
                          <Text size="sm" fw={500}>
                            {login.profile.display}
                          </Text>
                          <Text c="dimmed" size="xs">
                            {login.project.display}
                          </Text>
                        </div>
                      </Group>
                    </Menu.Item>
                  )
              )}
              <Menu.Divider />
              <Group justify="center">
                <SegmentedControl
                  size="xs"
                  value={colorScheme}
                  onChange={(newValue) => setColorScheme(newValue as MantineColorScheme)}
                  data={[
                    { label: 'Light', value: 'light' },
                    { label: 'Dark', value: 'dark' },
                    { label: 'Auto', value: 'auto' },
                  ]}
                />
              </Group>
              <Menu.Divider />
              <Menu.Item leftSection={<IconSwitchHorizontal size={14} stroke={1.5} />} onClick={() => navigate('/signin')}>
                Add another account
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                leftSection={<IconSettings size={14} stroke={1.5} />}
                // onClick={() => navigate(`/${getReferenceString(profile as ProfileResource)}`)}
                onClick={() => navigate('/account/profile')}
              >
                Account settings
              </Menu.Item>
              <Menu.Item
                leftSection={<IconLogout size={14} stroke={1.5} />}
                onClick={async () => {
                  await medplum.signOut();
                  // navigate('/signin');     //Use if want to navigate to Signin Page
                  navigate('/');
                }}
              >
                Sign out
              </Menu.Item>
              <Text size="xs" c="dimmed" ta="center">
                {props.version}
              </Text>
            </Menu.Dropdown>
            ) : (
              <Menu.Dropdown>
                <Group justify="center">
                  <SegmentedControl
                    size="xs"
                    value={colorScheme}
                    onChange={(newValue) => setColorScheme(newValue as MantineColorScheme)}
                    data={[
                      { label: 'Light', value: 'light' },
                      { label: 'Dark', value: 'dark' },
                      { label: 'Auto', value: 'auto' },
                    ]}
                  />
                </Group>
                <Menu.Divider />
                <Menu.Item
                  leftSection={<IconLogin size={14} stroke={1.5} />}
                  onClick={async () => {
                    // await medplum.signOut();
                    navigate('/signin');     //Use if want to navigate to Signin Page
                    // navigate('/');
                  }}
                >
                  Sign-in
                </Menu.Item>
                <Text size="xs" c="dimmed" ta="center">
                  {props.version}
                </Text>
              </Menu.Dropdown>
            )}
    </>
  );
}