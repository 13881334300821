import { Document } from '@medplum/react';
import { ChatGpt } from '../components/ChatGpt';

export function AssistantPage(): JSX.Element {
  // const medplum = useMedplum();

  return (
    <Document width={800}>
      <div>
        <ChatGpt />
      </div>
    </Document>
  );
}
