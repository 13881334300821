import { showNotification } from '@mantine/notifications';
import { deepClone, normalizeErrorString, normalizeOperationOutcome } from '@medplum/core';
import { OperationOutcome, Resource } from '@medplum/fhirtypes';
import { Document, ResourceForm, useMedplum } from '@medplum/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ResourceElementKeys, ResourcePartialForm } from '../../components/ResourcePartialForm'

export function EditTab(): JSX.Element | null {
  const medplum = useMedplum();
  const { patientId } = useParams() as { patientId: string };
  const [value, setValue] = useState<Resource | undefined>();
  const navigate = useNavigate();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const patientElementKeys: ResourceElementKeys[] = [
    {key: 'name', title: '',description: ''},
    {key: 'telecom', title: 'Contact Info',description: ''},
    {key: 'gender', title: '',description: ''},
    {key: 'birthDate', title: '', description: ''},
    {key: 'address', title: '', description: ''},
    {key: 'photo', title: '', description: ''},
    {key: 'contact', title: '',description: ''}
  ] 

  useEffect(() => {
    medplum
      .readResource('Patient', patientId)
      .then((resource) => setValue(deepClone(resource)))
      .catch((err) => {
        setOutcome(normalizeOperationOutcome(err));
        showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false });
      });
  }, [medplum, patientId]);

  const handleSubmit = useCallback(
    (newResource: Resource): void => {
      setOutcome(undefined);
      medplum
        .updateResource(newResource)
        .then(() => {
          navigate(`/Patient/${patientId}/timeline`);
          showNotification({ color: 'green', message: 'Success' });
        })
        .catch((err) => {
          setOutcome(normalizeOperationOutcome(err));
          showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false });
        });
    },
    [medplum, patientId, navigate]
  );

  if (!value) {
    return null;
  }

  return (
    <Document>
      <ResourcePartialForm 
        defaultValue={value} 
        onSubmit={handleSubmit} 
        outcome={outcome} 
        resourceElementKeys={patientElementKeys}
      />
    </Document>
  );
}
