/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react';
import {
  Card,
  Accordion,
  Collapse,
  Grid,
  Group,
  Progress,
  Tooltip,
  CloseButton,
  Alert,
  Text,
  Button,
} from '@mantine/core';
import { useWSIUploadList, UploadItem } from '../../context/WSIUploadListContext';
import { IconInfoCircle } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

export function WSIUploadItem({ id }: UploadItem) {
  const { removeUploadFromList, uploadList } = useWSIUploadList();
  const [opened, { toggle }] = useDisclosure(false);
  const item = uploadList.find((item) => item.id === id);

  useEffect(() => {}, [id]);

  return (
    <Card shadow="xs" padding="md" radius="md" style={{ marginBottom: '10px' }}>
      <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text size="xl">{item?.id}</Text>
        <CloseButton onClick={() => removeUploadFromList(id)} />
      </Group>

      <Grid>
        <Grid.Col span="auto">
          <Progress.Root size={35}>
            <Tooltip label={<ol>{item?.history.map((item, index) => <li key={index}>{item}</li>)} </ol>}>
              <Progress.Section
                // @ts-expect-error: value parameter works
                value={item?.progress}
                color={
                  item?.state === 'Error'
                    ? 'red'
                    : item?.state === 'Finished'
                      ? 'green'
                      : item?.state === 'Uploading'
                        ? 'blue'
                        : 'yellow'
                }
              >
                <Progress.Label>{item?.state === 'Uploading' ? `${item?.progress}%` : item?.state}</Progress.Label>
              </Progress.Section>
            </Tooltip>
          </Progress.Root>
        </Grid.Col>
        <Grid.Col span={item?.state === 'Finished' ? 2 : 0}>
          {item?.state === 'Finished' && (
            <Button onClick={toggle} variant="link" style={{ width: '100%' }}>
              Results
            </Button>
          )}
        </Grid.Col>
      </Grid>
      <Collapse in={opened}>
        <>
          <Text>StudyInstanceUID: {item?.results?.StudyInstanceUID}</Text>
          <Accordion>
            {item?.results?.SeriesInstances?.map((series) => {
              return (
                <Accordion.Item key={series.SeriesInstanceUID} value={series.SeriesInstanceUID}>
                  <Accordion.Control> {`SeriesInstanceUID: ${series.SeriesInstanceUID}`}</Accordion.Control>
                  <Accordion.Panel>
                    <Text>SeriesInstanceUID: {series.SeriesInstanceUID}</Text>
                    {series.Instances.map((instace) => {
                      return (
                        <Grid key={instace.SOPInstanceUID} style={{ marginTop: '10px' }}>
                          <Grid.Col span={2}>
                            <Text>{`SOPInstanceUID: ${instace.SOPInstanceUID}`}</Text>
                          </Grid.Col>
                          <Grid.Col span={10}>
                            {Object.entries(instace).map(([key, value]) => {
                              return (
                                <Grid key={key}>
                                  <Grid.Col span={3}>
                                    <Text>{key}</Text>
                                  </Grid.Col>
                                  <Grid.Col span={9}>
                                    <Text>{value}</Text>
                                  </Grid.Col>
                                </Grid>
                              );
                            })}
                          </Grid.Col>
                        </Grid>
                      );
                    })}
                    ;
                  </Accordion.Panel>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </>
      </Collapse>

      {item?.state === 'Error' && (
        <Alert title="Error" color="red" style={{ marginTop: '10px' }} icon={<IconInfoCircle />}>
          {item?.history?.map((message, index) => <Text key={index}>{message}</Text>)}
          {item?.errorMessages?.map((message, index) => <Text key={index}>{message}</Text>)}
        </Alert>
      )}
    </Card>
  );
}
