import { Paper, Title, Image, Box, Grid, Anchor, Container, Card, Flex, Text, Badge, useMantineTheme } from '@mantine/core';
import classes from './SearchPage.module.css';
import OpenEpic from '../img/open-epic.png'


export function ImportPatientPage(): JSX.Element {
  const theme = useMantineTheme();

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <Box p="lg">
        <Container>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Flex>
              <Image src={OpenEpic} m="-30px 20px -30px -30px" w="30%" />
              <div>
                <Badge color={theme.primaryColor} size="xl">
                  Coming Soon
                </Badge>
                <Text size="lg" fw={500} mt="md">
                  PathCloud Integration to Open-EPIC Endpoints
                </Text>
                <Text size="sm" color="dimmed" my="sm">
                  PathCloud's native FHIR architecture and robust integration services enables rapid integration 
                  with 100's of Epic Electronic Health Records.  
                  Epic enables the secure flow of patient data between Epic sites 
                  and FHIR-enabled systems.
                </Text>
                <Anchor href="https://open.epic.com/MyApps/Endpoints"  target="_blank" fz="lg" fw={500}>
                  List of Available EPIC Facilities
                </Anchor>
              </div>
            </Flex>
          </Card>
        </Container>
      </Box>
    </Paper>
  );
}
