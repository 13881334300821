import { LoginAuthenticationResponse } from '@medplum/core';
// import { OperationOutcome } from '@medplum/fhirtypes';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Document, useMedplum } from '@medplum/react';

// import { NewPathcloudUserForm } from './NewPathcloudUserForm';
import { NewUserForm } from './NewUserForm';

export interface RegisterFormProps {
  // readonly type: 'patient' | 'project' | 'practitioner';
  readonly projectId?: string;
  readonly clientId?: string;
  readonly googleClientId?: string;
  readonly recaptchaSiteKey?: string;
  readonly children?: ReactNode;
  readonly onSuccess: () => void;
}

export function RegisterForm(props: RegisterFormProps): JSX.Element {
  const { projectId, clientId, googleClientId, recaptchaSiteKey, onSuccess } = props;
  const medplum = useMedplum();
  const navigate = useNavigate();
  const [login, setLogin] = useState<string>();

  function handleAuthResponse(response: LoginAuthenticationResponse): void {
    if (response.code) {
      medplum
        .processCode(response.code)
        .then(() => onSuccess())
        .catch(console.log);
    } else if (response.login) {
      setLogin(response.login);
      navigate('/signin?loginstatus=first');
    }
  }

  return (
    <Document width={450}>
      {!login && (
        // <NewPathcloudUserForm
        //   projectId={projectId as string}
        //   clientId={clientId}          googleClientId={googleClientId}
        //   recaptchaSiteKey={recaptchaSiteKey}
        //   handleAuthResponse={handleAuthResponse}
        // >
        //   {props.children}
        // </NewPathcloudUserForm>
        <NewUserForm
        projectId={projectId as string}
        clientId={clientId}
        googleClientId={googleClientId}
        recaptchaSiteKey={recaptchaSiteKey}
        handleAuthResponse={handleAuthResponse}
      >
        {props.children}
      </NewUserForm>
      )}
    </Document>
  );
}
