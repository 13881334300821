// "Project/23d78337-6526-427c-ae2c-9528a9e5f0d7" - User Project (Family1)
// "Project/9bdf199e-c9d9-4e92-94a6-284c14c53971" - PathCloud Project 
// "Organization/6e391613-9ebe-43fa-bbca-5cee33138451 - PublicOrg
// "Organization/6036b74f-6832-45ad-b293-6c3c5248901f" - UserOrg (Family1)
// "Endpoint/fb2a4ee6-c967-4578-b301-3ddac3599947" 
export const query_ImagingStudyList = `{
  ImagingStudyList
  $queryFilter
  {
    meta {
      lastUpdated,
      project,
      author {
        reference,
        display,
      }
    }
    resourceType,
    id,
    identifier {
      value
    },
    description,
    started,
    imageStatus: status,
    numberOfSeries,
    location {
      display
    }
    endpoint {
      reference,
      display
    }
    series {
      uid,
      description,
      modality {
        display
        code
        },
      bodySite {
        display
        },
      started,
      numberOfInstances,
    }
    endpoint: endpoint {
      Endpoint: resource {
        ... on Endpoint {
          resourceType
          id
          name
          status
          address
        }
      }
    }
		subj_Patient: subject {
      Patient: resource {
        ... on Patient {
        id,
        name (use: "official"){ given, family },
        birthDate
        }   
      }
		}
		servicerequest: basedOn {
      ServiceRequest: resource {
        ... on ServiceRequest {
          resourceType,
          id,
          meta {
            lastUpdated,
          },
          servicerequestStatus: status
          diagnosticreport: DiagnosticReportList(_reference: based_on) {
            resourceType,
            id,
            reportStatus: status,
            meta {
              lastUpdated
            }
          }
        } 
      }
    }
  }
}`
export const query_ImagingStudyList_PUBLIC01 = `{
  ImagingStudyList
  $queryFilter
  {
    meta {
      lastUpdated,
      project,
      author {
        reference,
        display,
      }
    }
    resourceType,
    id,
    identifier {
      value
    },
    description,
    started,
    imageStatus: status,
    numberOfSeries,
    location {
      display
    }
    endpoint {
      reference,
      display
    }
    series {
      uid,
      description,
      modality {
        display
        code
        },
      bodySite {
        display
        },
      started,
      numberOfInstances,
    }
    endpoint: endpoint {
      Endpoint: resource {
        ... on Endpoint {
          resourceType
          id
          name
          status
          address
        }
      }
    }
		subj_Patient: subject {
      Patient: resource {
        ... on Patient {
        id
        }   
      }
		}
		servicerequest: basedOn {
      ServiceRequest: resource {
        ... on ServiceRequest {
          resourceType,
          id,
          meta {
            lastUpdated,
          },
          servicerequestStatus: status
          diagnosticreport: DiagnosticReportList(_reference: based_on) {
            resourceType,
            id,
            reportStatus: status,
            meta {
              lastUpdated
            }
          }
        } 
      }
    }
  }
}`
export const query_DiagnosticReportList = `{
  DiagnosticReportList 
  (
    _count: 100
    _compartment: "Organization/$queryOrgID" 
  ) 
  {
    id,
    reportStatus: status,
    meta {
      lastUpdated
    }
		subj_Patient: subject {
      Patient: resource {
        ... on Patient {
        id,
        name (use: "official"){ given, family },
        birthDate
        }   
      }
		}
		imagingStudy {
      ImagingStudy: resource {
        ... on ImagingStudy {
          meta {
            lastUpdated,
            project,
            author {
              reference,
              display,
            }
          }
          resourceType,
          id,
          identifier {
            value
          },
          description,
          started,
          imageStatus: status,
          numberOfSeries,
          location {
            display
          }
          endpoint {
            reference,
            display
          }
          procedureCode {
            coding {
              display
            }
          }
          series {
            uid,
            description,
            modality {
              display
              code
              },
            bodySite {
              display
              },
            started,
            numberOfInstances,
          }
        }
      }
    }
    taskid: TaskList(_reference: subject) {
      resourceType,
      id
      taskStatus: status
      intent   
    }
  }
}`;
export const query_DiagnosticReportList_PUBLIC = `{
  DiagnosticReportList 
  (
    _count: 100
    _compartment: "Project/$queryProjectID" 
  ) 
  {
    id,
    reportStatus: status,
    meta {
      lastUpdated
    }
		subj_Patient: subject {
      Patient: resource {
        ... on Patient {
        id,
        name (use: "official"){ given, family },
        birthDate
        }   
      }
		}
		imagingStudy {
      ImagingStudy: resource {
        ... on ImagingStudy {
          meta {
            lastUpdated,
            project,
            author {
              reference,
              display,
            }
          }
          resourceType,
          id,
          identifier {
            value
          },
          description,
          started,
          imageStatus: status,
          numberOfSeries,
          location {
            display
          }
          procedureCode {
            coding {
              display
            }
          }
          series {
            uid,
            description,
            modality {
              display
              code
              },
            bodySite {
              display
              },
            started,
            numberOfInstances,
          }
        }
      }
    }
    taskid: TaskList(_reference: subject) {
      resourceType,
      id
      taskStatus: status
      intent   
    }
  }
}`;